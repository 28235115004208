import React from 'react';
import { Container, Divider, Typography, Button, Box, List, ListItem, ListItemIcon } from '@mui/material';
import { motion } from 'framer-motion'; // Importation de framer-motion
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Icône flèche MUI
import { Cloud, AccountTree, Book, School } from '@mui/icons-material'; // Icônes MUI
import Header1 from 'components/headers/Header1';

// navigate react router
import { useNavigate } from 'react-router-dom';
const OpenDays = () => {
    const navigate = useNavigate();
    return (
        <Container 
        className="icontainer"
        sx={{ mx: 'auto', padding: '4rem' }}>
            {/* Titre avec Header1 */}
            <Divider sx={{ marginBottom: '2rem' }} />

            <Header1 text="Interested in joining ITRC?" />

            {/* Partie texte avec flex row */}
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" gap={4} sx={{ marginBottom: '2rem' }}>
                {/* Partie texte avec liste des fonctionnalités */}
                <Box sx={{ flex: 1 }}>
                    <Typography variant="body1" paragraph sx={{ textAlign: 'left', fontSize: '18px', lineHeight: '1.6' }}>
                        Our club is here to elevate the level of students and train experts in the field. By joining, you will gain access to:
                    </Typography>

                    {/* Liste avec icônes */}
                    <List sx={{ paddingLeft: 0 }}>
                        <ListItem>
                            <ListItemIcon>
                                <Cloud sx={{ color: 'rgb(10, 77, 128)', fontSize: '30px' }} />
                            </ListItemIcon>
                            <Typography variant="body2">Web hosting services</Typography>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AccountTree sx={{ color: 'rgb(10, 77, 128)', fontSize: '30px' }} />
                            </ListItemIcon>
                            <Typography variant="body2">Project management system</Typography>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Book sx={{ color: 'rgb(10, 77, 128)', fontSize: '30px' }} />
                            </ListItemIcon>
                            <Typography variant="body2">Resources like e-books, datasets...</Typography>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <School sx={{ color: 'rgb(10, 77, 128)', fontSize: '30px' }} />
                            </ListItemIcon>
                            <Typography variant="body2">Courses and formations</Typography>
                        </ListItem>
                    </List>

                    <Typography variant="body1" paragraph sx={{ textAlign: 'left', fontSize: '18px', lineHeight: '1.6', marginTop: '20px' }}>
                        Join us now to start your journey towards becoming an expert!
                    </Typography>
                </Box>
            </Box>

            {/* Bouton animé avec flèche */}
            <motion.div
                initial={{ x: -200, opacity: 0 }}  // Position de départ et opacité
                animate={{ x: 0, opacity: 1 }}    // Position finale et opacité
                transition={{ type: 'spring', stiffness: 100 }}  // Animation fluide avec ressort
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/join')} // Redirection vers la page de formulaire
                    sx={{
                        backgroundColor: 'rgb(10, 77, 128)', // Couleur du bouton
                        borderRadius: '30px', // Bord arrondi
                        display: 'flex',
                        alignItems: 'center',
                        padding: '12px 30px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: 'rgb(8, 64, 98)', // Couleur au survol
                        },
                    }}
                >
                    JOIN NOW ITRC
                    {/* Flèche animée */}
                    <motion.div
                        animate={{ x: [0, 10, 0] }} // Animation de mouvement horizontal
                        transition={{ repeat: Infinity, duration: 1, ease: 'easeInOut' }}
                    >
                        <ArrowForwardIcon sx={{ ml: 2, fontSize: '24px' }} />
                    </motion.div>
                </Button>
            </motion.div>
        </Container>
    );
};

export default OpenDays;
