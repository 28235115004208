import React, { useState } from "react";
import { TextField, Button, Typography, Grid, Box, MenuItem, Select, InputLabel, FormControl, FormHelperText } from "@mui/material";
import axios from "axios";
import { useDropzone } from "react-dropzone";

const BASE_API_URL = "https://api.datalgeria.com/ebooks";  // Changez l'URL selon votre configuration backend

const AddEbookForm = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [authors, setAuthors] = useState("");
  const [tags, setTags] = useState("");
  const [category, setCategory] = useState("");
  const [year, setYear] = useState("");
  const [isbn, setIsbn] = useState("");
  const [publisher, setPublisher] = useState("");
  const [language, setLanguage] = useState("fr");
  const [pdfFile, setPdfFile] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf",
    onDrop: acceptedFiles => setPdfFile(acceptedFiles[0]),
  });

  const { getRootProps: getCoverRootProps, getInputProps: getCoverInputProps } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => setCoverImage(acceptedFiles[0]),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("authors", authors);
    formData.append("tags", tags);
    formData.append("category", category);
    formData.append("year", year);
    formData.append("isbn", isbn);
    formData.append("publisher", publisher);
    formData.append("language", language);
    formData.append("pdfFile", pdfFile);
    formData.append("coverImage", coverImage);

    try {
      await axios.post(BASE_API_URL, formData, { headers: { "Content-Type": "multipart/form-data" } });
      alert("E-book ajouté avec succès!");
      // Reset form after successful upload
      setTitle("");
      setDescription("");
      setAuthors("");
      setTags("");
      setCategory("");
      setYear("");
      setIsbn("");
      setPublisher("");
      setLanguage("fr");
      setPdfFile(null);
      setCoverImage(null);
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'e-book:", error);
      alert("Une erreur s'est produite lors de l'ajout de l'e-book.");
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto", padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Ajouter un E-book
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Titre"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Auteurs"
              value={authors}
              onChange={(e) => setAuthors(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Mots-clés (séparés par des virgules)"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Catégorie</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              >
                <MenuItem value="Sciences">Sciences</MenuItem>
                <MenuItem value="Littérature">Littérature</MenuItem>
                <MenuItem value="Technologie">Technologie</MenuItem>
                <MenuItem value="Art">Art</MenuItem>
                {/* Add more categories as needed */}
              </Select>
              <FormHelperText>Choisissez la catégorie de l'e-book</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Année de publication"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="ISBN"
              value={isbn}
              onChange={(e) => setIsbn(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Éditeur"
              value={publisher}
              onChange={(e) => setPublisher(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Langue</InputLabel>
              <Select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                required
              >
                <MenuItem value="fr">Français</MenuItem>
                <MenuItem value="ar">Arabe</MenuItem>
                <MenuItem value="en">Anglais</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box {...getRootProps()} sx={{ border: "2px dashed #ccc", padding: 2, textAlign: "center" }}>
              <input {...getInputProps()} />
              <Typography>Déposez le fichier PDF ici ou cliquez pour sélectionner</Typography>
            </Box>
            {pdfFile && <Typography>Fichier sélectionné: {pdfFile.name}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <Box {...getCoverRootProps()} sx={{ border: "2px dashed #ccc", padding: 2, textAlign: "center" }}>
              <input {...getCoverInputProps()} />
              <Typography>Déposez l'image de couverture ici ou cliquez pour sélectionner</Typography>
            </Box>
            {coverImage && <Typography>Image sélectionnée: {coverImage.name}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Ajouter l'E-book
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddEbookForm;
