import React from 'react';

const Heading = ({ level, text }) => {
  switch (level) {
    case 1:
      return <h1 className="editorjs-content-heading editorjs-content-heading-1">{text}</h1>;
    case 2:
      return <h2 className="editorjs-content-heading editorjs-content-heading-2">{text}</h2>;
    case 3:
      return <h3 className="editorjs-content-heading editorjs-content-heading-3">{text}</h3>;
    case 4:
      return <h4 className="editorjs-content-heading editorjs-content-heading-4">{text}</h4>;
    default:
      return <h5 className="editorjs-content-heading editorjs-content-heading-5">{text}</h5>;
  }
};

export default Heading;
