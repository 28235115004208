import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Chip,
  useTheme,
  Fade
} from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import {
  TrendingUp,
  School,
  Person,
  Engineering,
  Group,

} from '@mui/icons-material';

const MembersCount = () => {
  const theme = useTheme();
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getStatusColor = (status) => {
    switch (status) {
      case 'intern':
        return "#0A4D80";
      case 'member':
        return "#0A3757";
      case 'staff':
        return "#0a202d";
      case 'student':
        return "#0A4D80";
      case 'teacher':
        return "#0A3757";
      case 'partner':
        return "#0a202d";
      default:
        return theme.palette.grey[500];
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'intern':
        return <School fontSize="small" />;
      case 'member':
        return <Person fontSize="small" />;
      case 'staff':
        return <Engineering fontSize="small" />;
      case 'student':
        return <School fontSize="small" />;
      case 'teacher':
        return <Person fontSize="small" />;
      case 'partner':
        return <Group fontSize="small" />;
      default:

        return <Group fontSize="small" />;
    }
  };

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1); // Last month
        const endDate = new Date();

        const response = await axios.get(
          'https://api.datalgeria.com/club/getUniversityMembersCount',
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              startDate: startDate.toISOString(),
              endDate: endDate.toISOString()
            }
          }
        );
        setStatistics(response.data.data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  // Pie Chart Options
  const pieChartOptions = {
    chart: {
      type: 'donut',
      animations: {
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    labels: ['Interns', 'Members', 'Staff'],
    colors: ["#0A4D80", "#0A3757", "#0a202d"],
    legend: {
      position: 'bottom',
      formatter: function(val, opts) {
        const value = opts.w.globals.series[opts.seriesIndex];
        return `${val}: ${value}`;
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              formatter: function (w) {
                const total = w.globals.seriesTotals.reduce((a, b) => Number(a) + Number(b), 0);
                return total.toString();
              }
            }
          }
        }
      }
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return value.toString();
        }
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 280
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  // Bar Chart Options
  const barChartOptions = {
    chart: {
      type: 'bar',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 8,
        distributed: true
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#0A4D80", "#0A3757", "#0a202d"],
    xaxis: {
      categories: ['Interns', 'Members', 'Staff'],
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Number of Members'
      }
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <Typography variant="h5" color="error">{error}</Typography>
      </Box>
    );
  }

  if (!statistics) return null;

  const { total, interns, members, staff, validationIncrease } = statistics;
  const { student, teacher, partner } = statistics.memberTypeData;
  
  const seriesData = [Number(interns), Number(members), Number(staff)];

  return (
    <Box sx={{ padding: 3, backgroundColor: 'transparent' }}>
      <Grid container spacing={3}>
        {/* Total Members Card */}
        <Grid item xs={12} lg={4}>
          <Fade in timeout={1000}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                height: '75%',
                backgroundColor: '#fff',
                boxShadow: 'none',
                borderRadius: '8px',
                '&:hover': { transform: 'translateY(-5px)' }
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                  Total Members
                </Typography>
                <Typography
                  variant="h3"
                  color="primary"
                  sx={{ fontWeight: 700, mb: 2 }}
                >
                  {total}
                </Typography>
                {validationIncrease && (
                  <Box sx={{ mt: 2 }}>
                    <Chip
                      icon={<TrendingUp />}
                      label={`${validationIncrease}% increase`}
                      color={parseFloat(validationIncrease) > 0 ? "success" : "default"}
                      sx={{
                        fontSize: '0.9rem',
                        '& .MuiChip-icon': { fontSize: '1.2rem' }
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={{ mt: 3 }}>
              {[{ label: 'Students', 
              status: 'student',
              value: student }, { label: 'Teachers', 
                status: 'teacher',
                value: teacher }, { label: 'Partners',
                  status: 'partner',
                  value: partner }]
                  .map(item => (
                    
                    <Box
                      key={item.status}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 1.5
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', 
width: '30%', justifyContent: 'center'

                      }}>
                        {getStatusIcon(item.status)}
                        <Typography>{item.label}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',
width: '30%'

                       }}>

                      <Typography
                      sx={{ textAlign: 'center', fontWeight: 600 }}
                      >{item.value}</Typography>
                      </Box>
                      <Chip
                        size="small"
                        label={item.value}
                        sx={{
                          backgroundColor: getStatusColor(item.status),
                          color: 'transparent',
                          fontWeight: 600
                        }}
                      />
                    </Box>
                  ))}
              </Box>
            </Paper>
          </Fade>
        </Grid>

        {/* Donut Chart */}
        <Grid item xs={12} lg={4}>
          <Fade in timeout={1200}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                height: '100%',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                borderRadius: '8px',
                '&:hover': { transform: 'translateY(-5px)' }
              }}
            >
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, textAlign: 'center' }}>
                Role Distribution
              </Typography>
              <ReactApexChart
                options={pieChartOptions}
                series={seriesData}
                type="donut"
                height={250}
              />
            </Paper>
          </Fade>
        </Grid>

        {/* Bar Chart */}
        <Grid item xs={12} lg={4}>
          <Fade in timeout={1400}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                height: '100%',
                backgroundColor: 'transparent',
                borderRadius: '8px',
                boxShadow: 'none',
                '&:hover': { transform: 'translateY(-5px)' }
              }}
            >
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, textAlign: 'center' }}>
                Member Count Breakdown
              </Typography>
              <ReactApexChart
                options={barChartOptions}
                series={[{ name: 'Members', data: seriesData }]}
                type="bar"
                height={250}
              />
            </Paper>
          </Fade>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MembersCount;
