// src/parts/Image.js
import React from 'react';

const Image = ({ url, caption, withBorder, withBackground, stretched }) => {
  return (
    <div
      className={`editorjs-content-image ${withBorder ? 'editorjs-content-border' : ''} ${withBackground ? 'editorjs-content-background' : ''} ${stretched ? 'editorjs-content-stretched' : ''}`}
    >
      <img src={url} alt={caption} />
      {caption && <p className="caption">{caption}</p>}
    </div>
  );
};

export default Image;
