import React from "react";
import Slider from "react-slick";
import { Divider } from "@mui/material";
import './Companies.css'; // Importe le fichier CSS pour styliser

const Companies = () => {
  const companies = [
    { id: 1, name: "Company 1", logo: "/images/partners/condor.png" },
    { id: 2, name: "Company 2", logo: "/images/partners/datalgeria.svg" },
    { id: 3, name: "Company 3", logo: "/images/partners/aih.svg" },
    { id: 4, name: "Company 4", logo: "/images/partners/univ-bba.jpg" },
    { id: 5, name: "Company 5", logo: "/images/partners/fmi.png" },
    { id: 6, name: "Company 6", logo: "/images/partners/hostilna.svg" },
    { id: 7, name: "Company 6", logo: "/images/partners/incubator.png" },
  ];

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Vitesse du défilement
    speed: 3000, // Durée pour qu'un cycle complet se termine
    cssEase: "linear", // Animation fluide
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div 
    className="companies-carousel icontainer">
                  <Divider sx={{ marginBottom: '2rem' }} />

      <Slider {...settings}>
        {companies.map((company) => (
          <div key={company.id} className="company-logo">
            <img
              src={company.logo}
              alt={company.name}
              style={{ maxWidth: "100%", maxHeight: "80px" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Companies;
