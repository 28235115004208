import React, { useRef } from 'react';
import {
  Box,
  Typography,
  Divider,
  Avatar,
  IconButton,
} from '@mui/material';
import { ArrowForward, ArrowBack } from '@mui/icons-material'; // Material Icons for arrows
import FilesManager from '../Files/FilesManager';
import TasksComponent from './TasksComponent'; // Import the TasksComponent
import ProjectRoadmap from './ProjectRoadMap';


const UserItem = ({ user, isCreator, isManager, onClick }) => (
  <Box
    onClick={onClick}
    sx={{ textAlign: 'center', margin: '0 10px', position: 'relative' }}
  >
    <Avatar
      src={user.picture}
      sx={{
        width: 80,
        height: 80,
        margin: '0 auto', // Center the avatar horizontally
      }}
    />
    {isCreator && isManager && (
      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          right: -65,
          backgroundColor: '#00796b',
          color: 'white',
          padding: '2px 8px',
          borderRadius: '16px',
          fontSize: '0.75rem',
          fontWeight: 'bold',
        }}
      >
        Creator and Manager
      </Box>
    )}
    {isCreator && !isManager && (
      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          right: 5,
          backgroundColor: '#00796b',
          color: 'white',
          padding: '2px 8px',
          borderRadius: '16px',
          fontSize: '0.75rem',
          fontWeight: 'bold',
        }}
      >
        Creator
      </Box>
    )}
    {isManager && !isCreator && (
      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          right: -30,
          backgroundColor: '#00796b',
          color: 'white',
          padding: '2px 8px',
          borderRadius: '16px',
          fontSize: '0.75rem',
          fontWeight: 'bold',
        }}
      >
        Manager
      </Box>
    )}
    <Typography variant="body2" sx={{ textAlign: 'center' }}>
      {user.fname} {user.name}
    </Typography>
  </Box>
);

const UniversityItem = ({ university, onClick }) => (
    <Box
      onClick={onClick}
      sx={{
        textAlign: 'center',
        margin: '0 10px',
        padding: '10px 15px',
        borderRadius: '16px',
        backgroundColor: '#e0f7fa', // Light blue background
        transition: 'transform 0.3s, box-shadow 0.3s', // Animation for hover effects
        '&:hover': {
          transform: 'scale(1.05)', // Slightly enlarge on hover
        },
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#00796b' }}>
        {university.name}
      </Typography>
    </Box>
  );
  
const MainContent = ({ projectId, page, selectedStep, selectedStepUsers, selectedStepName, projectData, setSelectedUserId, setSelectedUniversityId }) => {
  const users = projectData?.users || [];
  const universities = projectData?.universities || [];
  

  const creator = {
    name: projectData?.project_details?.creator_name,
    fname: projectData?.project_details?.creator_fname,
    picture: projectData?.project_details?.creator_picture,
  };
  const manager = {
    name: projectData?.project_details?.manager_name,
    fname: projectData?.project_details?.manager_fname,
    picture: projectData?.project_details?.manager_picture,
  };



  const userListRef = useRef(null);
  const universityListRef = useRef(null);

  const scroll = (ref, scrollAmount) => {
    if (ref.current) {
      ref.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };
  const isCreatorAndManagerSame = creator.name === manager.name;

  return (
    <Box sx={{ flexGrow: 1, padding: 3, backgroundColor: '#ffffff', overflowY: 'auto', overflowX: 'hidden', width: '50%' }}>
      {page === 'tasks' ? (
        <TasksComponent stepId={selectedStep}
            stepName={selectedStepName}
            users={selectedStepUsers}
            projectData={projectData}
        />
      ) : page === 'files' ? (
        <>
          <FilesManager projectId={projectId} />
          
       
        </>
      ) : page === 'overview' ? (
        <>
          <Typography
            variant="h4" gutterBottom>
            Overview for project <b>{projectData?.project_details?.name}</b>
          </Typography>
          <Box
            sx={{
              display: 'inline-block',
              padding: '8px 12px',
              borderRadius: '16px',
              backgroundColor: '#e0f7fa', // Light blue background for the tag
              color: '#00796b', // Darker text color
              fontWeight: 'bold',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.05)', // Slightly enlarge on hover
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', // Add shadow on hover
              },
            }}
          >
            {projectData?.project_details?.id}
          </Box>
          <Divider sx={{ marginY: 3 }} />
  {/* Managers Section */}
  <Typography variant="h5" gutterBottom>
            Project Managers
          </Typography>
          <Box sx={{ position: 'relative', zIndex: 1, display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => scroll(userListRef, -200)}><ArrowBack /></IconButton>
            <Box ref={userListRef} sx={{ display: 'flex', overflowX: 'hidden', scrollBehavior: 'smooth', whiteSpace: 'nowrap', flexGrow: 1 }}>
              {/* Only render one user if creator and manager are the same */}
              {!isCreatorAndManagerSame ? (
                <>
                  <UserItem onClick={() => setSelectedUserId(creator.id)} key={creator.name} user={creator} isCreator={true} isManager={false} />
                  <UserItem onClick={() => setSelectedUserId(manager.id)} key={manager.name} user={manager} isCreator={false} isManager={true} />
                </>
              ) : (
                <UserItem onClick={() => setSelectedUserId(creator.id)} key={creator.name} user={creator} isCreator={true} isManager={true} />
              )}
            </Box>
            <IconButton onClick={() => scroll(userListRef, 200)}><ArrowForward /></IconButton>
          </Box>

          <Divider sx={{ marginY: 3 }} />

          {/* Users Section */}
          <Typography variant="h5" gutterBottom>
            Users
          </Typography>
          <Box sx={{
            position: 'relative', zIndex: 1,
            display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => scroll(userListRef, -200)}><ArrowBack /></IconButton>
            <Box ref={userListRef} sx={{ display: 'flex', overflowX: 'hidden', scrollBehavior: 'smooth', whiteSpace: 'nowrap', flexGrow: 1 }}>
              {users.length > 0 ? (
                users.map((user) => (
                  <UserItem 
                  onClick={() => setSelectedUserId(user.id)}
                  key={user.id} user={user} />
                ))
              ) : (
                <Typography variant="body2">No users available</Typography>
              )}
            </Box>
            <IconButton onClick={() => scroll(userListRef, 200)}><ArrowForward /></IconButton>
          </Box>

          <Divider sx={{ marginY: 3 }} />

          {/* Universities Section */}
          <Typography variant="h5" gutterBottom>
            Universities
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => scroll(universityListRef, -200)}><ArrowBack /></IconButton>
            <Box ref={universityListRef} sx={{ display: 'flex', overflowX: 'hidden', scrollBehavior: 'smooth', whiteSpace: 'nowrap', flexGrow: 1 }}>
              {universities.length > 0 ? (
                universities.map((university) => (
                  <UniversityItem 
                  onClick={() => setSelectedUniversityId(university.id)}
                  key={university.id} university={university} />
                ))
              ) : (
                <Typography variant="body2">No universities available</Typography>
              )}
            </Box>
            <IconButton onClick={() => scroll(universityListRef, 200)}><ArrowForward /></IconButton>
          </Box>
          {/* Project Roadmap Section */}
<Divider sx={{ marginY: 3 }} />
<Typography variant="h5" gutterBottom>
  Project Roadmap
</Typography>
<ProjectRoadmap projectData={projectData} />

        </>
      ) : null}
    </Box>
  );
};

export default MainContent;
