import React, { useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";

const IsTranslationTrue = ({ text, translation }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [sourceText, setSourceText] = useState(text);
  const [originalText, setOriginalText] = useState(text);

  const handleCorrection = () => {
    setOriginalText(sourceText);
    setIsEditing(true);
  };

  const handleConfirm = () => {
    setIsEditing(false);
    alert("Phrase mise à jour avec succès !");
  };

  const handleCancel = () => {
    setSourceText(originalText);
    setIsEditing(false);
  };

  const handleValidate = () => {
    alert("Traduction validée !");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding={4}
      borderRadius="16px"
      boxShadow="0px 6px 20px rgba(0,0,0,0.15)"
      bgcolor="#f9fafb"
      width="100%"
      maxWidth="600px"
      margin="auto"
      textAlign="center"
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#333", marginBottom: 3 }}
      >
        Vérification de Traduction
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        marginBottom={4}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontStyle: "italic",
            color: "#555",
            marginBottom: 1,
            textAlign: "center",
          }}
        >
          Phrase en darija :
        </Typography>
        {isEditing ? (
          <TextField
            variant="outlined"
            fullWidth
            value={sourceText}
            onChange={(e) => setSourceText(e.target.value)}
            sx={{
              marginBottom: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
          />
        ) : (
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              color: "#1976d2",
              backgroundColor: "#e3f2fd",
              padding: "8px",
              borderRadius: "8px",
            }}
          >
            {sourceText}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{
            fontStyle: "italic",
            color: "#555",
            marginTop: 3,
            marginBottom: 1,
            textAlign: "center",
          }}
        >
          Traduction proposée :
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: "#388e3c",
            backgroundColor: "#e8f5e9",
            padding: "8px",
            borderRadius: "8px",
          }}
        >
          {translation}
        </Typography>
      </Box>
      {!isEditing ? (
        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            color="success"
            onClick={handleValidate}
            sx={{
              paddingX: 4,
              fontSize: "1rem",
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            Valider
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCorrection}
            sx={{
              paddingX: 4,
              fontSize: "1rem",
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            Corriger
          </Button>
        </Box>
      ) : (
        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            sx={{
              paddingX: 4,
              fontSize: "1rem",
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            Confirmer
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCancel}
            sx={{
              paddingX: 4,
              fontSize: "1rem",
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            Annuler
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default IsTranslationTrue;
