import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  TextField,
  Avatar,
  IconButton
} from "@mui/material";
import { styled } from '@mui/material/styles';
import UserDialog from 'components/dashboard/UserDialog'; // Import the UserDialog component
// icone "information"
import InfoIcon from '@mui/icons-material/Info';
import RenderModal from "components/editor/RenderModal";
import Header1 from "components/headers/Header1";
// Styled components for better design
const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '8px',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
}));

const SearchField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    transition: 'border-color 0.3s',
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.dark,
    },
  },
}));

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const PAGE_SIZE = 10;
  const authToken = localStorage.getItem('authToken');

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');

  const handleDescriptionModalClose = () => {
    setDescriptionModalOpen(false);
  };

  const handleDescriptionModalOpen = (description) => {
    setCurrentDescription(description);
    setDescriptionModalOpen(true);
  };



  const handleOpenDialog = (userId) => {
    setSelectedUserId(userId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUserId(null);
  };

  const typeDetails = {
    official: {
      icon: '🏢',
      color: '#3f51b5',
    },
    personal: {
      icon: '🏠',
      color: '#ff5722',
    },
    event: {
      icon: '🎉',
      color: '#4caf50',
    },
  };

  
  const fetchProjects = async () => {
    const response = await axios.get(`https://api.datalgeria.com/club/projects`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        page,
        search
      }
    });

    setProjects(response.data.projects);
    setTotal(response.data.total);
  };

  useEffect(() => {
    fetchProjects();
  }, [page, search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to page 1 when a new search is initiated
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <StyledBox>
      <Header1 text="Projects" />

      {/* Search bar */}
      <SearchField
        label="Search Projects"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
            <StyledTableCell>Nom du Projet</StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Manager</StyledTableCell>
              <StyledTableCell>Created by</StyledTableCell>
              <StyledTableCell>
                Creation Date
              </StyledTableCell>

              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => (
              <TableRow key={project.id} hover>
<TableCell >
  <Box  sx={{ display: 'flex', alignItems: 'center'}}>
  <Typography variant="body1" sx={{ flexGrow: 1 }}>
    {project.name}
  </Typography>

  {/* Display the information icon */}
  <IconButton
    onClick={() => handleDescriptionModalOpen(project.description)}
    sx={{
      padding: '4px', // Reduced padding for a cleaner look
      cursor: 'pointer',
      color: '#0a202d', // Make the icon color consistent with your theme
      '&:hover': {
        color: '#0a202d', // Hover effect for the icon
      },
      transition: 'color 0.3s ease', // Smooth color transition
    }}
  >
    <InfoIcon />
  </IconButton>
</Box>
</TableCell>


                <TableCell>

<Box
sx={{
display: 'inline-block',
padding: '5px 8px',
borderRadius: '16px',
backgroundColor: '#e0f7fa', // Light blue background for the tag
color: '#00796b', // Darker text color
fontSize: 8,
fontWeight: 'bold',
transition: 'transform 0.3s, box-shadow 0.3s',
'&:hover': {
transform: 'scale(1.05)', // Slightly enlarge on hover
boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', // Add shadow on hover
},
}}
>
{project.id}
</Box>

</TableCell>
<TableCell>
  <Box
    sx={{
      display: 'inline-flex',
      alignItems: 'center',
      padding: '5px 8px',
      borderRadius: '16px',
      backgroundColor: typeDetails[project.type]?.color || '#e0e0e0', // Default color if type is not found
       // White text color
        fontSize: 12,
        fontWeight: 'bold',
          }}
        >
          {typeDetails[project.type]?.icon} {project.type}
        </Box>
      </TableCell>


  
      <TableCell>
  <Avatar 
  onClick={() => handleOpenDialog(project.project_manager)}
  src={project.project_manager_picture} alt={project.project_manager_name} sx={{ width: 32, height: 32 }} />
  <Typography variant="caption">{project.project_manager_name} {project.project_manager_fname}</Typography>
</TableCell>

      <TableCell>
  <Avatar 
  onClick={() => handleOpenDialog(project.created_by)}
  src={project.created_by_picture} alt={project.created_by_name} sx={{ width: 32, height: 32 }} />
  <Typography variant="caption">{project.created_by_name} {project.created_by_fname}</Typography>
</TableCell>


<TableCell>{new Date(project.creation_date).toLocaleDateString()}</TableCell>


              <TableCell>
            
            <Button 
              component={Link} 
              to={`/dashboard/project/${project.id}`} 
              variant="contained" 
              color="primary"
              style={{ 
                transition: 'background-color 0.3s',
                backgroundColor: '#0a202d',
                color: '#fff',
                // no box shadow
                boxShadow: 'none',
                // rounded more
                borderRadius: '20px',
              }}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0d2b3a"}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#0a202d"}
            >
              Select
            </Button>
              </TableCell>

            </TableRow>
          ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
      <Pagination
        count={Math.ceil(total / PAGE_SIZE)}
        page={page}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />

<UserDialog open={openDialog} onClose={handleCloseDialog} userId={selectedUserId} />

<RenderModal data={currentDescription} open={descriptionModalOpen} onClose={handleDescriptionModalClose} />
    </StyledBox>
  );
};

export default Projects;
