import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Pagination,
    InputAdornment,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material'; 
import axios from 'axios';
import { Tooltip } from 'react-tooltip';

const UniversitiesTable = () => {
    const [universities, setUniversities] = useState([]);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [search, setSearch] = useState('');
    const [type, setType] = useState('all'); // 'all' or 'club'
    const authToken = localStorage.getItem('authToken');
    const PAGE_SIZE = 10;

    useEffect(() => {
        fetchUniversities();
    }, [page, type, search]);

    const fetchUniversities = async () => {
        try {
            const response = await axios.get('https://api.datalgeria.com/club/getUniversities', {
                params: {
                    page: page + 1, // API expects page to start from 1
                    search,
                    type,
                    pageSize: PAGE_SIZE,
                },
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setUniversities(response.data.data);
            setTotalCount(response.data.total);
        } catch (error) {
            console.error('Error fetching universities:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage - 1); // Adjust for zero-based index
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleTypeChange = (newType) => {
        setType(newType);
        setPage(0); // Reset page to 0 on type change
    };

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                Universities List
            </Typography>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <TextField
                    label="Search Universities"
                    variant="outlined"
                    value={search}
                    onChange={handleSearchChange}
                    style={{ flexGrow: 1 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon style={{ cursor: 'pointer' }} onClick={() => fetchUniversities()} />
                            </InputAdornment>
                        ),
                    }}
                    data-tip="Search universities by name or wilaya"
                />
            </Box>
            <Box mb={2}>
                <Button 
                    variant={type === 'all' ? 'contained' : 'outlined'} 
                    onClick={() => handleTypeChange('all')}
                    style={{ marginRight: '10px' }}
                >
                    All
                </Button>
                <Button 
                    variant={type === 'club' ? 'contained' : 'outlined'} 
                    onClick={() => handleTypeChange('club')}
                >
                    With Club
                </Button>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Wilaya</TableCell>
                            <TableCell>Has Club</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {universities.map((university) => (
                            <TableRow key={university.id}>
                                <TableCell>{university.name}</TableCell>
                                <TableCell>{university.wilaya}</TableCell>
                                <TableCell>{university.has_club ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={Math.ceil(totalCount / PAGE_SIZE)} 
                page={page + 1} 
                onChange={handleChangePage}
                style={{ marginTop: '20px' }}
            />
            <Tooltip place="top" effect="solid" />
        </Box>
    );
};

export default UniversitiesTable;
