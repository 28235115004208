import React from 'react';

const Checklist = ({ items }) => {
  return (
    <ul className="editorjs-content-checklist">
      {items.map((item, index) => (
        <li key={index} className="editorjs-content-checklist-item">
          <input
            type="checkbox"
            checked={item.checked}
            readOnly
            className="editorjs-content-checklist-checkbox"
          />
          {item.text}
        </li>
      ))}
    </ul>
  );
};

export default Checklist;
