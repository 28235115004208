import React from 'react';
import { useParams } from 'react-router-dom';
import PDFReader from './PDFReader';

const BookReader = () => {
    const { pdfUrl } = useParams();
    
    // On décode l'URL pour l'utiliser
    const decodedPdfUrl = decodeURIComponent(pdfUrl);

    return <PDFReader pdfUrl={decodedPdfUrl} />;
};

export default BookReader;