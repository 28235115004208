import React from 'react';

const Code = ({ code }) => {
  return (
    <pre className="editorjs-content-code">
      <code>{code}</code>
    </pre>
  );
};

export default Code;
