import React from 'react';

const Header = (props) => {
    return (
        <header 
            {...props}
            style={styles.header}>
        </header>
    );
};

const styles = {
    header: {
        position: "fixed", // Utilisation de "fixed" pour que l'élément soit fixé à l'écran
        top: '50',
        right: '0',
        left: '0',
        bottom: '0', // Fixer le header au bas de la fenêtre
        width: '100%',
        height: '100%', // Définir une hauteur spécifique pour le fond
        background: 'radial-gradient(circle, #424bff 0%, #2493ee 24%, #37eaf7 44%, #c624ee 57%, transparent 70%)',
        filter: 'blur(100px)',
        opacity: '3%',
        padding: '20px',
        textAlign: 'center',
        zIndex: '-1', // Placer derrière les autres éléments pour éviter l'interférence
        pointerEvents: 'none', // Assurer que le fond ne capture pas les clics

    },
    title: {
        color: 'white',
        fontSize: '24px',
    },
};

export default Header;
