import React, { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { styled } from '@mui/system';
import * as Icons from '@mui/icons-material';
import { Avatar, Typography, LinearProgress, Box } from '@mui/material';
import ProfileDetails from './ProfileDetails'; // Import the component

import { Scrollbar } from 'react-scrollbars-custom'; // Import Scrollbar
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

// Define menu items structure
const menuItems = [
  { header: "Home", items: [{ title: "Dashboard", to: "/", icon: <Icons.DashboardOutlined /> }] },

  { header: "President", items: [
    { title: "New project", to: "/president/new-project", icon: <Icons.AddCircleOutline /> },
    { title: "Projects", to: "/president/projects",   icon: <Icons.AssignmentOutlined /> },
    { title: "New dataset", to: "/datasets/new-dataset", icon: <Icons.NoteAddOutlined /> }
  ]}, 
  { header: "GRH", items: [
    { title: "Members", to: "/members", icon: <Icons.PeopleOutlineOutlined /> },
    { title: "Validation", to: "/validations", icon: <Icons.VerifiedUserOutlined /> },
    { title: "Universities", to: "/universities", icon: <Icons.SchoolOutlined /> }
  ]},

  { header: "Communication", items: [
    { title: "Social Medias", to: "/social-medias", icon: <Icons.ShareOutlined /> },
    { title: "Discord", to: "/discord", icon: <Icons.ChatOutlined /> },
    { title: "Emails", to: "/emails", icon: <Icons.EmailOutlined /> },
    { title: "Notifications", to: "/notifications", icon: <Icons.NotificationsOutlined /> },
    { title: "New Article", to: "/cm/article-editor", icon: <Icons.PostAddOutlined /> },
    { title: "Articles", to: "/articles", icon: <Icons.ArticleOutlined /> }
  ]},

  { header: "Main", items: [
    { title: "Events", to: "/calendar", icon: <Icons.EventOutlined /> },
    { title: "Projects", to: "/projects", icon: <Icons.AssignmentOutlined /> },
    { title: "Datasets", to: "/datasets", icon: <Icons.StorageOutlined /> },
    { title: "Ideas", to: "/ideas", icon: <Icons.LightbulbOutlined /> }
  ]},

  { header: "Others", items: [
    { title: "Profile", to: "/profile", icon: <Icons.PersonOutlined /> },
    { title: "Settings", to: "/settings", icon: <Icons.SettingsOutlined /> },
    { title: "Logout", to: "/logout", icon: <Icons.ExitToAppOutlined /> }
  ]}
];

// Styled Sidebar
const StyledSidebar = styled(Sidebar)(({ theme, isCollapsed }) => ({
  height: '100vh', 
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: '#ffffff',
  overflowY: 'auto',
  width: isCollapsed ? '80px !important' : '250px !important', // Adjust width based on collapse state
  borderRight: '1px solid #e0e0e0',
  padding: '0px 0',
  minWidth: '80px !important',
  transition: 'width 0.3s ease', // Add smooth transition
}));

// Menu Header Style
const MenuHeader = styled('div')(({ isCollapsed }) => ({
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#444',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  opacity: 1,
  display: isCollapsed ? 'none' : 'block', // Hide header when collapsed
}));

const MenuItem2 = styled(motion.div)(({ isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 24px',
  cursor: 'pointer',
  color: isSelected ? '#2563eb' : '#4b5563',
  backgroundColor: isSelected ? 'rgba(37, 99, 235, 0.1)' : 'transparent',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: isSelected ? 'rgba(37, 99, 235, 0.15)' : 'rgba(0, 0, 0, 0.04)',
  },
}));


const MenuText = styled(motion.span)({
  marginLeft: '12px',
  fontSize: '14px',
  whiteSpace: 'nowrap',
});

const IconWrapper = styled(motion.div)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
});


const sidebarVariants = {
  expanded: {
    width: '250px',
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
  collapsed: {
    width: '80px',
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const textVariants = {
  expanded: {
    opacity: 1,
    x: 0,
    display: 'block',
    transition: {
      duration: 0.2,
      delay: 0.1,
    },
  },
  collapsed: {
    opacity: 0,
    x: -20,
    transitionEnd: {
      display: 'none',
    },
    transition: {
      duration: 0.2,
    },
  },
};

const iconVariants = {
  expanded: { rotate: 0 },
  collapsed: { rotate: 360, transition: { duration: 0.4 } },
};

const MenuSection = styled('div')({
  padding: '8px 0',
});
const ProfileSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderBottom: '1px solid #e0e0e0',
  gap: theme.spacing(1),
}));



const SidebarComponent = ({ isCollapsed }) => {
  const location = useLocation();
  const [profile, setProfile] = useState({
    picture: '',
    name: '',
    fname: '',
    xp: 0,
    level: 0,
    remainingXp: 0,
  });


  
  useEffect(() => {
    const sidebarContainer = document.querySelector('[data-testid="ps-sidebar-container-test-id"]');
    if (sidebarContainer) {
      sidebarContainer.style.overflowY = 'hidden'; // Ou auto/hidden
      sidebarContainer.style.overflowX = 'hidden';
    }
  }, []);


    
  useEffect(() => {
    const picture = localStorage.getItem('picture');
    const name = localStorage.getItem('name');
    const fname = localStorage.getItem('fname');
    const xp = parseInt(localStorage.getItem('xp') || '92600', 10);
    const level = Math.floor(xp / 1000);
    const remainingXp = xp % 1000;

    setProfile({ picture, name, fname, xp, level, remainingXp });
  }, []);



  const isItemSelected = (path) => {
    return location.pathname === `/dashboard${path}`;
  };

  return (
    <>
      <StyledSidebar isCollapsed={isCollapsed}>
      <ProfileDetails 
      visible={isCollapsed}
      profile={profile} />
<Scrollbar 
      // color of the thumb
      style={{ height: '100%', width: '100%' }}>

<AnimatePresence>
        {menuItems.map((section, sectionIndex) => (
          <MenuSection key={sectionIndex}>
            <MenuHeader
              variants={textVariants}
              // if collapsed set fontsize 5
              style={{ opacity: isCollapsed ? 0 : 1 }}
              animate={isCollapsed ? 'collapsed' : 'expanded'}
            >
              {section.header}
            </MenuHeader>
            
            {section.items.map((item, itemIndex) => {
              const isSelected = isItemSelected(item.to);
              
              return (
                <Link
                  key={itemIndex}
                  to={`/dashboard${item.to}`}
                  style={{ textDecoration: 'none' }}
                >
                  <MenuItem2
                    isSelected={isSelected}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <IconWrapper
                      variants={iconVariants}
                      animate={isCollapsed ? 'collapsed' : 'expanded'}
                    >
                      {React.cloneElement(item.icon, {
                        style: { 
                          color: isSelected ? '#2563eb' : '#4b5563',
                          fontSize: '20px'
                        }
                      })}
                    </IconWrapper>
                    
                    <MenuText
                      variants={textVariants}
                      animate={isCollapsed ? 'collapsed' : 'expanded'}
                    >
                      {item.title}
                    </MenuText>
                  </MenuItem2>
                </Link>
              );
            })}
      </MenuSection>
    ))}
    {/* Ajouter un espace en bas */}
    {!isCollapsed && 
    <div style={{ height: '250px' }}></div>
    }
  </AnimatePresence>
</Scrollbar>
      </StyledSidebar>
    </>
  );
};

export default SidebarComponent;
