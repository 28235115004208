// Warning.js
import React from 'react';

const Warning = ({ title, message }) => {
  return (
    <div 
    className="editorjs-content-warning">
      <strong>{title}</strong>
      <p>{message}</p>
    </div>
  );
};

export default Warning;
