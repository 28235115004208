// PDFReader.js
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Box, IconButton, Paper, Slider, Typography, AppBar, Toolbar,
  Button, CircularProgress
} from '@mui/material';
import { 
  ZoomIn as ZoomInIcon, 
  ZoomOut as ZoomOutIcon,
  KeyboardArrowLeft as PrevIcon,
  KeyboardArrowRight as NextIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  ArrowBack
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { Document, Page, pdfjs } from 'react-pdf';

// Configuration de react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
// Composant de lecture PDF
export const PDFReader = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

console.log(pdfUrl);

  const handleZoomIn = () => {
    if (scale < 2.0) setScale(prev => prev + 0.1);
  };

  const handleZoomOut = () => {
    if (scale > 0.5) setScale(prev => prev - 0.1);
  };

  const handlePrevPage = () => {
    setPageNumber(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setPageNumber(prev => Math.min(prev + 1, numPages || prev));
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setError(null);
  }

  function onDocumentLoadError(err) {
    setError("Impossible de charger le PDF. Veuillez réessayer.");
    console.error("Erreur de chargement du PDF:", err);
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box sx={{ 
        height: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        bgcolor: '#f5f5f5'
      }}>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <IconButton onClick={handleBack} edge="start" sx={{ mr: 2 }}>
              <ArrowBack />
            </IconButton>

            <Box display="flex" alignItems="center">
              <IconButton onClick={handleZoomOut}>
                <ZoomOutIcon />
              </IconButton>
              <Slider
                value={scale}
                min={0.5}
                max={2.0}
                step={0.1}
                onChange={(_, value) => setScale(value)}
                sx={{ width: 100, mx: 2 }}
              />
              <IconButton onClick={handleZoomIn}>
                <ZoomInIcon />
              </IconButton>
            </Box>

            <Box display="flex" alignItems="center">
              <IconButton onClick={handlePrevPage} disabled={pageNumber <= 1}>
                <PrevIcon />
              </IconButton>
              <Typography variant="body1" sx={{ mx: 2 }}>
                Page {pageNumber} / {numPages || '-'}
              </Typography>
              <IconButton onClick={handleNextPage} disabled={pageNumber >= (numPages || 1)}>
                <NextIcon />
              </IconButton>
            </Box>

            <IconButton onClick={toggleFullscreen}>
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box 
          sx={{ 
            flex: 1,
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            p: 3
          }}
        >
          {error ? (
            <Paper sx={{ p: 3, m: 3 }}>
              <Typography color="error" align="center">
                {error}
              </Typography>
              <Button
                variant="contained"
                onClick={handleBack}
                sx={{ mt: 2 }}
              >
                Retour à la liste
              </Button>
            </Paper>
          ) : (
            <Paper 
              elevation={3}
              sx={{ 
                p: 2,
                bgcolor: 'white',
                display: 'inline-block'
              }}
            >
              <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
                loading={
                  <Box 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center" 
                    height={800}
                  >
                    <CircularProgress />
                  </Box>
                }
              >
                <AnimatePresence mode="wait">
                  <motion.div
                    key={pageNumber}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Page
                      pageNumber={pageNumber}
                      scale={scale}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  </motion.div>
                </AnimatePresence>
              </Document>
            </Paper>
          )}
        </Box>
      </Box>
    </motion.div>
  );
};
export default PDFReader;