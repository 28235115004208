import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Typography, IconButton } from '@mui/material';
import { Add as AddIcon, MoreVert as MoreVertIcon, Info as InfoIcon } from '@mui/icons-material';
import Task from './Task';
import RenderModal from 'components/editor/RenderModal';

const Section = ({ hasRightGlobal, section, index, onAddTask, projectData }) => {
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');

  const handleDescriptionModalOpen = () => {
    setCurrentDescription(section.description); // Set description from the section
    setDescriptionModalOpen(true); // Open the modal
  };

  const handleDescriptionModalClose = () => {
    setDescriptionModalOpen(false); // Close the modal
  };

  return (
    <>
      <Draggable draggableId={section.id} index={index} isDragDisabled={!hasRightGlobal}>
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{
              width: 300,
              bgcolor: 'grey.100',
              borderRadius: 1,
              p: 2,
              flexShrink: 0,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
              {...provided.dragHandleProps}
            >
              <Typography
                variant="h6"
                sx={{
                  maxWidth: 'calc(100% - 48px)', // Subtract width of icons
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {section.name} ({section.tasks.length})
              </Typography>
              <Box
                sx={{
                  display: 'flex',  // Ensures icons are aligned in a row
                  gap: 1,  // Adds space between the icons
                }}
              >
                {/* Add Task Button */}
                <IconButton size="small" onClick={onAddTask}>
                  <AddIcon />
                </IconButton>

                {/* Info Icon to open description modal */}
                <IconButton size="small" onClick={handleDescriptionModalOpen}>
                  <InfoIcon />
                </IconButton>

                {/* More Options Button */}
                <IconButton size="small">
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Box>

            <Droppable droppableId={section.id} type="task">
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{ minHeight: 100 }}
                >
                  {section.tasks.map((task, index) => (
                    <Task 
                      projectData={projectData}
                      key={task.id} task={task} index={index} />
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
        )}
      </Draggable>

      {/* Render the modal with the section description */}
      <RenderModal 
        data={currentDescription}
        open={descriptionModalOpen}
        onClose={handleDescriptionModalClose}
      />
    </>
  );
};

export default Section;
