import React, { useState } from "react";
import { Box, Card, CardContent, Typography, IconButton, Tooltip, Stack, Button } from "@mui/material";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const SentimentAnalysis = ({ text = "Que pensez-vous de cette phrase en darija ?" }) => {
  const [selectedSentiment, setSelectedSentiment] = useState(null);

  const sentiments = [
    {
      value: "positive",
      label: "Positif",
      icon: <SentimentVerySatisfiedIcon sx={{ color: "#66bb6a", fontSize: 40 }} />,
      color: "#66bb6a",
    },
    {
      value: "negative",
      label: "Négatif",
      icon: <SentimentVeryDissatisfiedIcon sx={{ color: "#ef5350", fontSize: 40 }} />,
      color: "#ef5350",
    },
    {
      value: "neutral",
      label: "Neutre",
      icon: <SentimentNeutralIcon sx={{ color: "#ffa726", fontSize: 40 }} />,
      color: "#ffa726",
    },
    {
      value: "not_darija",
      label: "Ce n'est pas en Darija",
      icon: <WarningAmberIcon sx={{ color: "#fbc02d", fontSize: 40 }} />,
      color: "#fbc02d",
    },
  ];

  const handleSentimentSelect = (sentiment) => {
    setSelectedSentiment(sentiment);
  };

  return (
    <Card
      sx={{
        maxWidth: 600,
        margin: "20px auto",
        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        borderRadius: 2,
        p: 2,
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ mb: 3, fontWeight: 600, color: "#1a237e", textAlign: "center" }}
        >
          Analyse de Sentiment
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            mb: 4,
            fontSize: 18,
            textAlign: "center",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            p: 2,
          }}
        >
          {text}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={2} sx={{ mb: 3 }}>
          {sentiments.map((sentiment) => (
            <Tooltip key={sentiment.value} title={sentiment.label} arrow>
              <IconButton
                onClick={() => handleSentimentSelect(sentiment.value)}
                sx={{
                  backgroundColor:
                    selectedSentiment === sentiment.value ? sentiment.color + "30" : "transparent",
                  "&:hover": {
                    backgroundColor: sentiment.color + "30",
                  },
                  transition: "all 0.3s",
                  border: `2px solid ${selectedSentiment === sentiment.value ? sentiment.color : "transparent"}`,
                  borderRadius: "50%",
                }}
              >
                {sentiment.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Stack>
        {selectedSentiment && (
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              fontSize: 16,
              color: sentiments.find((s) => s.value === selectedSentiment)?.color,
              fontWeight: 500,
            }}
          >
            Sentiment sélectionné :{" "}
            {sentiments.find((s) => s.value === selectedSentiment)?.label}
          </Typography>
        )}
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSelectedSentiment(null)}
            sx={{ fontWeight: 600 }}
          >
            Réinitialiser
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SentimentAnalysis;
