import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card, Box, Typography, IconButton, Avatar, Tooltip } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Info as InfoIcon } from '@mui/icons-material';
import RenderModal from 'components/editor/RenderModal';

const Task = ({ task, index, projectData }) => {
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');

  // Filter projectData.users to get only the users assigned to the task
  if (!projectData.users) return null;
  const assignedUsers = projectData.users.filter(user => 
    task.users.some(taskUser => taskUser.user_id === user.id)
  );

  const handleDescriptionModalOpen = () => {
    setCurrentDescription(task.description); // Set description from the task
    setDescriptionModalOpen(true); // Open the modal
  };

  const handleDescriptionModalClose = () => {
    setDescriptionModalOpen(false); // Close the modal
  };

  return (
    <>
      <Draggable draggableId={task.id} index={index} isDragDisabled={!task.canMove}>
        {(provided) => (
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{ mb: 1, bgcolor: 'white' }}
          >
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography>{task.name}</Typography>
              <Box>
                {/* Icons for Edit, Delete, and Description Modal */}
                <IconButton size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton size="small">
                  <DeleteIcon fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={handleDescriptionModalOpen}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>

            {/* Display assigned user names or avatars below */}
            <Box sx={{ pl: 2, pb: 2, display: 'flex', gap: 1 }}>
              {assignedUsers.map(user => (
                <Tooltip title={user.name} key={user.id}>
                  <Avatar src={user.picture} alt={user.name} />
                </Tooltip>
              ))}
            </Box>
          </Card>
        )}
      </Draggable>

      {/* Render the modal with the task description */}
      <RenderModal 
        data={currentDescription}
        open={descriptionModalOpen}
        onClose={handleDescriptionModalClose}
      />
    </>
  );
};

export default Task;
