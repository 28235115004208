// RenderModal.js
import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, CircularProgress, Typography } from '@mui/material';
import RenderEditorJSContent from './RenderEditorJSContent';
import PropTypes from 'prop-types';

const RenderModal = ({ data, open, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [contentData, setContentData] = useState(data);

  // Update modal content and show loading effect on data change
  useEffect(() => {
    setLoading(true); // Start loading
    setContentData(data); // Set new data
    setLoading(false); // Stop loading after setting data
  }, [data]);

  const handleClose = () => {
    if (onClose) onClose(); // Close the modal using the provided callback
  };

  
  return (
    <div>
      <Modal
            sx={{
                backdropFilter: 'blur(5px)', // Add blur effect to backdrop
              }}
      open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 4,
            maxHeight: '80vh',
            overflow: 'auto',
          }}
        >
          {loading ? (
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
              <CircularProgress />
              <Typography variant="body1" sx={{ ml: 2 }}>
                Loading content...
              </Typography>
            </Box>
          ) : (
            <RenderEditorJSContent data={contentData} />
          )}
          <Box mt={2} textAlign="center">
            <Button variant="outlined" onClick={handleClose} color="secondary">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

RenderModal.propTypes = {
  data: PropTypes.shape({
    time: PropTypes.number,
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        data: PropTypes.object,
      })
    ),
    version: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RenderModal;
