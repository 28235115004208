import React from 'react';
import Paragraph from './parts/Paragraph';
import Table from './parts/Table';
import List from './parts/List';
import Warning from './parts/Warning';
import Code from './parts/Code';
import Link from './parts/Link';
import RawHTML from './parts/RawHTML';
import Heading from './parts/Heading';
import Quote from './parts/Quote';
import Checklist from './parts/Checklist';
import Delimiter from './parts/Delimiter';
import Image from './parts/Image';  // Import the Image component
import './style.css';

const RenderEditorJSContent = ({ data }) => {
  if (!data) {
    return null;
  }

  let parsedData = data;
  if (typeof data === 'string') {
    try {
      parsedData = JSON.parse(data);
    } catch (error) {
      console.error("Error parsing JSON", error);
      return null;
    }
  }

  return (
    <div className="editorjs-content">
      {parsedData.blocks && parsedData.blocks.map(block => {
        switch (block.type) {
          case 'paragraph':
            return <Paragraph key={block.id} text={block.data.text} />;
          case 'table':
            return <Table key={block.id} content={block.data.content} withHeadings={block.data.withHeadings} />;
          case 'list':
            return <List key={block.id} items={block.data.items} style={block.data.style} />;
          case 'warning':
            return <Warning key={block.id} title={block.data.title} message={block.data.message} />;
          case 'code':
            return <Code key={block.id} code={block.data.code} />;
          case 'link':
            return <Link key={block.id} url={block.data.link} text={block.data.text} />;
          case 'raw':
            return <RawHTML key={block.id} html={block.data.html} />;
          case 'header':
            return <Heading key={block.id} level={block.data.level} text={block.data.text} />;
          case 'quote':
            return <Quote key={block.id} text={block.data.text} caption={block.data.caption} alignment={block.data.alignment} />;
          case 'checklist':
            return <Checklist key={block.id} items={block.data.items} />;
          case 'delimiter':
            return <Delimiter key={block.id} />;
          case 'image':
            return (
              <Image
                key={block.id}
                url={block.data.file.url}
                caption={block.data.caption}
                withBorder={block.data.withBorder}
                withBackground={block.data.withBackground}
                stretched={block.data.stretched}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default RenderEditorJSContent;
