import React from 'react';

const List = ({ items, style }) => {
  const listClass = style === 'ordered' ? 'editorjs-content-list-ordered' : 'editorjs-content-list-unordered';

  return (
    <div className={`editorjs-content-list ${listClass}`}>
      {style === 'ordered' ? (
        <ol>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
      ) : (
        <ul>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default List;
