import React, { useState, useEffect } from 'react';
import {
    Box,
    AppBar,
    Typography,
    CircularProgress,
    Alert,
    Chip,
    IconButton,
    Avatar,
    Divider,

    Button
} from '@mui/material';
import { School, LocationOn, CalendarToday, Category, AccountBalance, Close, Groups } from '@mui/icons-material';
import axios from 'axios';
import { useSpring, animated } from 'react-spring';
import { styled } from '@mui/material/styles';
import { Tree, TreeNode } from 'react-organizational-chart';
import UserDialog from 'components/dashboard/UserDialog'; // Import the UserDialog component
import MembersCount from 'components/dashboard/charts/MembersCount';
import DiscordCard from './DiscordCard';
// Styled components for the tree structure
const MemberCard = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    transition: 'all 0.3s',
}));

const StyledAvatar = styled(Avatar)(({ theme, role }) => ({
    width: role === 'president' ? 80 : role === 'vp' ? 70 : 60,
    height: role === 'president' ? 80 : role === 'vp' ? 70 : 60,
    border: `3px solid ${role === 'president' ? theme.palette.error.main :
        role === 'vp' ? theme.palette.primary.main : theme.palette.secondary.main}`,
    boxShadow: theme.shadows[4],
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}));

const RoleLabel = styled(Typography)(({ theme, role }) => ({
    fontSize: role === 'president' ? 18 : role === 'vp' ? 16 : 14,
    fontWeight: role === 'president' ? 700 : role === 'vp' ? 600 : 500,
    color: role === 'president' ? theme.palette.error.main :
        role === 'vp' ? theme.palette.primary.main : theme.palette.text.primary,
}));

const University = ({ universityId }) => {
    const [universityData, setUniversityData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const handleOpenDialog = (userId) => {
        setSelectedUserId(userId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedUserId(null);
    };

    useEffect(() => {
        if (selectedUserId) {
            handleOpenDialog(selectedUserId);
        }
    }, [selectedUserId]);

    useEffect(() => {
        if (universityId) {
            setLoading(true);
            setError(null);
            axios
                .get(`https://api.datalgeria.com/universities/university?university_id=${universityId}`)
                .then(response => {
                    setUniversityData(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des données:', error);
                    setError(error.message);
                    setLoading(false);
                });
        }
    }, [universityId]);

    // Helper function to generate unique IDs
    const generateNodeId = (role, index) => `${role}-${index}`;

    // Role title mapping
    const getRoleTitle = (role) => {
        const roles = {
            'president': 'Président',
            'vp': 'Vice Président',
            'tc': 'Technical Coordinator',
            'em': 'Event Manager',
            'cm': 'Community Manager',
            'lfm': 'Logistics Manager',
        };
        return roles[role] || role;
    };

    // Role color mapping
    const getRoleColor = (role) => {
        const colors = {
            'president': 'error',
            'vp': 'primary',
            'tc': 'secondary',
            'em': 'secondary',
            'cm': 'secondary',
            'lfm': 'secondary',
        };
        return colors[role] || 'default';
    };

    // Organizational structure rendering
    const renderMemberNode = (member, role) => (
        <MemberCard>
            <StyledAvatar
                onClick={() => handleOpenDialog(member.user_id)}
                role={role} src={member.picture} alt={member.name} />
            <Box>
                <RoleLabel role={role} variant="h6">
                    {member.name}
                </RoleLabel>
                <Chip size="small" label={getRoleTitle(role)} color={getRoleColor(role)} />
            </Box>
        </MemberCard>
    );

    const renderOrganizationalTree = (members) => {
        if (!members) return null;
    
        return (
            <Box sx={{ overflowX: 'auto', paddingBottom: 2 }}>
                <Tree label="Core Members">
                    {/* President Node */}
                    {members.president?.map((president, index) => (
                        <TreeNode
                            key={generateNodeId('president', index)}
                            label={renderMemberNode(president, 'president')}
                        >
                            {/* VP Node */}
                            {members.vp?.map((vp, vpIndex) => (
                                <TreeNode
                                    key={generateNodeId('vp', vpIndex)}
                                    label={renderMemberNode(vp, 'vp')}
                                >
                                    {/* Technical Coordinator */}
                                    {members.tc?.map((tc, tcIndex) => (
                                        <TreeNode
                                            key={generateNodeId('tc', tcIndex)}
                                            label={renderMemberNode(tc, 'tc')}
                                        />
                                    ))}
    
                                    {/* Event Manager */}
                                    {members.em?.map((em, emIndex) => (
                                        <TreeNode
                                            key={generateNodeId('em', emIndex)}
                                            label={renderMemberNode(em, 'em')}
                                        />
                                    ))}
    
                                    {/* Community Manager */}
                                    {members.cm?.map((cm, cmIndex) => (
                                        <TreeNode
                                            key={generateNodeId('cm', cmIndex)}
                                            label={renderMemberNode(cm, 'cm')}
                                        />
                                    ))}
    
                                    {/* Logistics Manager */}
                                    {members.lfm?.map((lfm, lfmIndex) => (
                                        <TreeNode
                                            key={generateNodeId('lfm', lfmIndex)}
                                            label={renderMemberNode(lfm, 'lfm')}
                                        />
                                    ))}
                                </TreeNode>
                            ))}
                        </TreeNode>
                    ))}
                </Tree>
            </Box>
        );
    };
    
    

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                <CircularProgress size={60} thickness={4} />
            </Box>
        );
    }

    if (error) {
        return (
            <Box>
                <Alert severity="error" variant="filled">
                    Erreur: {error}
                </Alert>
            </Box>
        );
    }

    if (!universityData) {
        return (
            <Box>
                <Alert severity="error" variant="filled">
                    Université non trouvée ou erreur de chargement
                </Alert>
            </Box>
        );
    }

    const { name, wilaya, year, type, authority, has_club, members } = universityData;

    return (
        <Box sx={{ minHeight: '80vh' }}>

            
<AppBar 
        position="static" 
        elevation={0}
        sx={{ 
          background:  '#0a202d',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          height: 200,
          display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        }}
      >
        <img src="/images/itrc-logo-dark.svg" alt="ITRC" style={{ height: "100%" }} />
        <img src={`https://cdn.datalgeria.com/universities/${universityId}.png`} alt={name} style={{ height: "50%" }} />



      </AppBar>
<Box class="stats-container">



            <Box sx={{ 
                
                display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>


                <Box>
      











                    <Box sx={{ 
                        
                        padding: 2,
                        display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        <Chip icon={<LocationOn />} label={wilaya} />
                        <Chip icon={<CalendarToday />} label={`Établi en ${year}`} />
                        <Chip icon={<Category />} label={type} />
                        <Chip icon={<AccountBalance />} label={authority} />
                    </Box>
                </Box>
                <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={() => { /* Close logic if needed */ }}>
                    <Close />
                </IconButton>
            </Box>
            <Box>
                <Box sx={{ 
                                    

                    display: 'flex', alignItems: 'center', gap: 1,
                    
                    padding: 2 }}>
                    <Typography variant="h6">Club:</Typography>
                    {has_club ? (
                        <Chip 
                        sx={{ padding: "5px 10px" }}
                        icon={<Groups />} label="Présent" color="success" />
                    ) : (
                        <Chip 
                        sx={{ padding: "5px 10px" }}

                        icon={<Groups />} label="Non disponible" color="error" />
                    )}
                </Box>
                <Box
                sx={{
                    

                }}>
                    <Divider />
                    <DiscordCard userId = {"1b2a2b20-ee6c-4c73-ae16-1bb86d6239aa"} />
<Divider />
                <MembersCount />

                </Box>


<Divider />
<br/><br/>
                {renderOrganizationalTree(members)}
                
                <br/><br/>
                <br/><br/>


                            </Box>
            <UserDialog open={openDialog} onClose={handleCloseDialog} userId={selectedUserId} />
        </Box>
        </Box>
    );
};

export default University;
