import React from 'react';
import Header1 from '../../components/global/Header1';
import Header from '../../components/global/Header';
import Hero2 from '../../components/global/Hero2';
import Club1 from '../../components/club/Club1';
import Club2 from '../../components/club/Club2';
import Security from '../../components/products/Security';
import Footer from '../../components/global/Footer';
import { Container, Box } from '@mui/material';
import Companies from 'components/global/Companies';
import Articles from 'components/global/Articles';
import OpenDays from 'components/club/OpenDays';
import { Helmet } from 'react-helmet-async';
const Home = () => {






  return (
    <>
    
    
<Helmet>
        <title>ITRC - I.T Reserch Community | Inter-university scientific club in Algeria</title>
        <meta name="description" content="Our research team is dedicated to advancing the field of artificial intelligence, with a focus on developing innovative solutions that address the unique challenges of the Algerian dialect. By leveraging cutting-edge technologies and methodologies, we aim to push the boundaries of what is possible in the realm of natural language processing and machine learning." />
        <meta property="og:title" content="ITRC - I.T Reserch Community | Inter-university scientific club in Algeria" />
        <meta property="og:description" content="Our research team is dedicated to advancing the field of artificial intelligence, with a focus on developing innovative solutions that address the unique challenges of the Algerian dialect. By leveraging cutting-edge technologies and methodologies, we aim to push the boundaries of what is possible in the realm of natural language processing and machine learning." />
      </Helmet>

      <Box>
      <Header />
      <Header1 />
      <Club1 />
      <Companies />
      <Articles />

      <OpenDays />


      <Footer />
    </Box>
      
      </>

  );
};

export default Home;
