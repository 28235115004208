import React from 'react';
import { Container } from '@mui/material';
import Grid1 from '../../components/grids/Grid1';
import Header1 from '../../components/headers/Header1';
import Card from '../../components/cards/Card'; // Import the Card <component></component>
import Card2 from '../../components/cards/Card2'; // Import the Card2 <component></component>
import Tags from '../../components/products/features/Tags'; // Import the Tags component

const Club1 = () => {
return (
    <Container 
    className="icontainer"
    sx={{ mx: 'auto', padding: '2rem'}}>
        <br />
        <br />
        <br />

        <Grid1>
            {/* First item spanning 2 columns */}
            <Card
                title="Researchs in AI"
                description="Our research team is dedicated to advancing the field of artificial intelligence, with a focus on developing innovative solutions that address the unique challenges of the Algerian dialect. By leveraging cutting-edge technologies and methodologies, we aim to push the boundaries of what is possible in the realm of natural language processing and machine learning."
                sx={{
                    gridColumn: { xs: 'span 1', md: 'span 2' }, // Spans 2 columns on medium screens and above
                }}
            />

            <Card
                title="Project Management"
                description="Our team of experienced project managers works closely with clients to define project scope, establish timelines, allocate resources, and monitor progress to ensure successful project outcomes."
                sx={{ gridColumn: { xs: 'span 1', md: 'span 1' } }} // Regular span
            />

            <Card2
                content={<img 
                    style={{ 
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        opacity: 0.5 }}
                    src="/images/itrc-cover.jpeg" alt="ITRC" />}
                sx={{ gridColumn: { xs: 'span 1', md: 'span 1' } }} // Regular span
            />

            {/* Last item spanning 2 columns */}
            <Card
                title="Providing Data"
                description="Data is the lifeblood of AI, and we are committed to providing high-quality, annotated datasets to fuel the development of AI applications and models. Our data sets cover a wide range of topics and domains, including sentiment analysis, named entity recognition, and more."
                content={<Tags tags={['NLP', 'Machine Learning', 'Data Science', 'AI']} />}
                sx={{
                    gridColumn: { xs: 'span 1', md: 'span 2' }, // Spans 2 columns on medium screens and above
                }}
            />
        </Grid1>
    </Container>
);
};

export default Club1;
