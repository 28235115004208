import React, { useState, useRef, useCallback, useEffect } from "react";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./editorTools";
import Header1 from "components/headers/Header1"
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";

import {
  Box,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tabs,
  Tab,
  Stack,
  Divider
} from "@mui/material";
import { Helmet } from 'react-helmet-async';



const steps = ["Informations de base", "Sélection des universités", "Sélection des membres", "Description"];
const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 }
};
const ProjectTypeCard = ({ type, title, description, selected, onClick }) => (
  <motion.div
    whileHover={{ scale: 1.03 }}
    whileTap={{ scale: 0.98 }}
  >
    <Card 
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        height: '100%',
        border: selected ? '2px solid #2196f3' : '1px solid rgba(0, 0, 0, 0.12)',
        transition: 'all 0.3s ease',
        position: 'relative',
        overflow: 'visible',
        backgroundColor: selected ? 'rgba(33, 150, 243, 0.05)' : 'white'
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>{title}</Typography>
        <Typography variant="body2" color="text.secondary">{description}</Typography>
        <AnimatePresence>
          {selected && (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              style={{
                position: 'absolute',
                top: -10,
                right: -10
              }}
            >
              <Chip 
                label="Sélectionné" 
                color="primary" 
                sx={{ 
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                  backgroundColor: '#2196f3'
                }} 
              />
            </motion.div>
          )}
        </AnimatePresence>
      </CardContent>
    </Card>
  </motion.div>
);

const UniversityCard = ({ university, selected, onToggle }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    layout
  >
    <Paper
      elevation={selected ? 3 : 1}
      sx={{
        p: 2,
        cursor: 'pointer',
        border: selected ? '2px solid #2196f3' : '1px solid rgba(0, 0, 0, 0.12)',
        minWidth: 200,
        textAlign: 'center',
        backgroundColor: selected ? 'rgba(33, 150, 243, 0.05)' : 'white',
        position: 'relative'
      }}
      onClick={onToggle}
    >
      <Typography variant="subtitle1" noWrap>
        {university.name}
      </Typography>
      <AnimatePresence>
        {selected && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
          >
            <Chip 
              label="Sélectionné" 
              color="primary" 
              size="small" 
              sx={{ mt: 1, backgroundColor: '#2196f3' }} 
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Paper>
  </motion.div>
);




const UserCard = ({ user, selected, isProjectManager, onToggle, onProjectManagerSelect }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    layout
  >
    <Card
      sx={{
        cursor: 'pointer',
        border: isProjectManager 
          ? '2px solid #4caf50'
          : selected 
            ? '2px solid #2196f3' 
            : '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: isProjectManager 
          ? 'rgba(76, 175, 80, 0.05)'
          : selected 
            ? 'rgba(33, 150, 243, 0.05)' 
            : 'white',
        position: 'relative'
      }}
      onClick={onToggle}
    >
      <CardContent>
        <Typography variant="subtitle1">{user.name}</Typography>
        <Box sx={{ mt: 1, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          <AnimatePresence>
            {selected && (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
              >
                <Chip 
                  label="Membre" 
                  color="primary" 
                  size="small" 
                />
              </motion.div>
            )}
            {isProjectManager && (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
              >
                <Chip 
                  label="Chef de Projet" 
                  color="success" 
                  size="small" 
                />
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
        {selected && !isProjectManager && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            <Button
              size="small"
              color="success"
              onClick={(e) => {
                e.stopPropagation();
                onProjectManagerSelect(user.user_id);
              }}
              sx={{ mt: 1 }}
            >
              Définir comme chef de projet
            </Button>
          </motion.div>
        )}
      </CardContent>
    </Card>
  </motion.div>
);





const AddProject = () => {
  const editorCore = useRef(null);
  const ReactEditorJS = createReactEditorJS();
  const [activeStep, setActiveStep] = useState(0);
  
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    projectType: "",
    selectedUniversities: [],
    selectedUsers: [],
    projectManager: ""
  });

  const [data, setData] = useState({
    universities: [],
    filteredUniversities: [],
    users: [],
    filteredUsers: []
  });

  const [search, setSearch] = useState({
    university: "",
    user: ""
  });

  const [loading, setLoading] = useState({
    universities: false,
    users: false,
    submit: false
  });

  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);






  const handleInitialize = useCallback((instance) => {
    instance._editorJS.isReady
      .then(() => {
        editorCore.current = instance;
      })
      .catch((err) => console.log("An error occurred", err));
  }, []);

  const fetchUniversities = async () => {
    setLoading(prev => ({ ...prev, universities: true }));
    try {
      const response = await axios.get("https://api.datalgeria.com/club/selectUniversities");
      setData(prev => ({
        ...prev,
        universities: response.data,
        filteredUniversities: response.data
      }));
    } catch (error) {
      console.error("Error fetching universities:", error);
    } finally {
      setLoading(prev => ({ ...prev, universities: false }));
    }
  };

  const fetchUsers = async (universityIds) => {
    setLoading(prev => ({ ...prev, users: true }));
    try {
      const response = await axios.get("https://api.datalgeria.com/club/selectMembers", {
        params: { universities: universityIds.join(',') }
      });
      setData(prev => ({
        ...prev,
        users: response.data,
        filteredUsers: response.data
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(prev => ({ ...prev, users: false }));
    }
  };

  const handleSave = async () => {
    setLoading(prev => ({ ...prev, submit: true }));
    try {
      const projectData = {
        name: formData.name,
        description: JSON.stringify(formData.description),
        universities: formData.selectedUniversities,
        users: formData.selectedUsers,
        project_manager: formData.projectManager,
        project_type: formData.projectType
      };

      await axios.post("https://api.datalgeria.com/president/addProject", projectData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        }
      });

      // Reset form and show success message
      setFormData({
        name: "",
        description: "",
        projectType: "",
        selectedUniversities: [],
        selectedUsers: [],
        projectManager: ""
      });
      setActiveStep(0);
      setOpenDescriptionModal(false);
    } catch (error) {
      console.error("Error saving project:", error);
    } finally {
      setLoading(prev => ({ ...prev, submit: false }));
    }
  };

  useEffect(() => {
    fetchUniversities();
  }, []);

  useEffect(() => {
    if (formData.selectedUniversities.length > 0) {
      fetchUsers(formData.selectedUniversities);
    }
  }, [formData.selectedUniversities]);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };


  const handleProjectManagerSelect = (userId) => {
    setFormData(prev => ({ ...prev, projectManager: userId }));
  };



  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 4 }}>
            <TextField
              label="Nom du Projet"
              variant="outlined"
              fullWidth
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              sx={{ mb: 4 }}
            />
            <Divider
            sx={{ mb: 4 }}
            />
            <Typography variant="h6" gutterBottom>Type de Projet</Typography>
            <Grid container spacing={3}>
              {[
                {
                  type: "official",
                  title: "Officiel",
                  description: "Projet impliquant une / plusieurs université"
                },
                {
                  type: "event",
                  title: "Évenement",
                  description: "Évenement impliquant une / plusieurs université"
                },
                {
                  type: "personal",
                  title: "Personnalisé",
                  description: "Projet sur mesure"
                }
              ].map((type) => (
                <Grid item xs={12} md={4} key={type.type}>
                  <ProjectTypeCard
                    {...type}
                    selected={formData.projectType === type.type}
                    onClick={() => setFormData(prev => ({ ...prev, projectType: type.type }))}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        );

        case 1:
          return (
              <Box sx={{ mt: 4 }}>
                  <TextField
                      label="Rechercher des universités"
                      variant="outlined"
                      fullWidth
                      value={search.university}
                      onChange={(e) => {
                          setSearch(prev => ({ ...prev, university: e.target.value }));
                          const filtered = data.universities.filter(uni =>
                              uni.name.toLowerCase().includes(e.target.value.toLowerCase())
                          );
                          setData(prev => ({ ...prev, filteredUniversities: filtered }));
                      }}
                      sx={{ mb: 3 }}
                  />
                  {/* Case à cocher pour tout sélectionner */}
                  <Divider
            sx={{ mb: 4 }}
            />
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Checkbox
                          checked={
                              formData.selectedUniversities.length === data.filteredUniversities.length &&
                              data.filteredUniversities.length > 0
                          }
                          onChange={(e) => {
                              if (e.target.checked) {
                                  // Sélectionne toutes les universités filtrées
                                  setFormData(prev => ({
                                      ...prev,
                                      selectedUniversities: data.filteredUniversities.map(uni => uni.id)
                                  }));
                              } else {
                                  // Désélectionne toutes les universités
                                  setFormData(prev => ({ ...prev, selectedUniversities: [] }));
                              }
                          }}
                      />
                      <span>Sélectionner toutes les universités</span>
                  </Box>
                  <Box
                      sx={{
                          display: 'flex',
                          gap: 2,
                          overflowX: 'auto',
                          pb: 2,
                          '::-webkit-scrollbar': {
                              height: '8px',
                          },
                          '::-webkit-scrollbar-thumb': {
                              backgroundColor: 'rgba(0,0,0,.2)',
                              borderRadius: '4px',
                          },
                      }}
                  >
                      {data.filteredUniversities.map((university) => (
                          <UniversityCard
                              key={university.id}
                              university={university}
                              selected={formData.selectedUniversities.includes(university.id)}
                              onToggle={() => {
                                  setFormData(prev => ({
                                      ...prev,
                                      selectedUniversities: prev.selectedUniversities.includes(university.id)
                                          ? prev.selectedUniversities.filter(id => id !== university.id)
                                          : [...prev.selectedUniversities, university.id]
                                  }));
                              }}
                          />
                      ))}
                  </Box>
              </Box>
          );

          case 2:
            return (
                <motion.div {...fadeInUp} transition={{ duration: 0.5 }}>
                    <Box sx={{ mt: 4 }}>
                        <TextField
                            label="Rechercher des membres"
                            variant="outlined"
                            fullWidth
                            value={search.user}
                            onChange={(e) => {
                                setSearch(prev => ({ ...prev, user: e.target.value }));
                                const filtered = data.users.filter(user =>
                                    user.name.toLowerCase().includes(e.target.value.toLowerCase())
                                );
                                setData(prev => ({ ...prev, filteredUsers: filtered }));
                            }}
                            sx={{ mb: 3 }}
                        />
                   <Divider
            sx={{ mb: 4 }}
            />
                        {/* Case à cocher pour tout sélectionner */}
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Checkbox
                                checked={
                                    formData.selectedUsers.length === data.filteredUsers.length &&
                                    data.filteredUsers.length > 0
                                }
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        // Sélectionne tous les membres filtrés
                                        setFormData(prev => ({
                                            ...prev,
                                            selectedUsers: data.filteredUsers.map(user => user.user_id)
                                        }));
                                    } else {
                                        // Désélectionne tous les membres
                                        setFormData(prev => ({ ...prev, selectedUsers: [] }));
                                    }
                                }}
                            />
                            <span>Sélectionner tous les membres</span>
                        </Box>
        
                        {formData.projectManager && (
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Chef de projet sélectionné
                                </Typography>
                                <Chip
                                    label={data.users.find(u => u.user_id === formData.projectManager)?.name}
                                    color="success"
                                    onDelete={() => setFormData(prev => ({ ...prev, projectManager: "" }))}
                                />
                            </Box>
                        )}
        
                        {/* Liste de membres avec défilement horizontal */}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                overflowX: 'auto',
                                pb: 2,
                                '::-webkit-scrollbar': {
                                    height: '8px',
                                },
                                '::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0,0,0,.2)',
                                    borderRadius: '4px',
                                },
                            }}
                        >
                            <AnimatePresence>
                                {data.filteredUsers.map((user) => (
                                    <UserCard
                                        key={user.user_id}
                                        user={user}
                                        selected={formData.selectedUsers.includes(user.user_id)}
                                        isProjectManager={formData.projectManager === user.user_id}
                                        onToggle={() => {
                                            setFormData(prev => ({
                                                ...prev,
                                                selectedUsers: prev.selectedUsers.includes(user.user_id)
                                                    ? prev.selectedUsers.filter(id => id !== user.user_id)
                                                    : [...prev.selectedUsers, user.user_id]
                                            }));
                                        }}
                                        onProjectManagerSelect={handleProjectManagerSelect}
                                    />
                                ))}
                            </AnimatePresence>
                        </Box>
                    </Box>
                </motion.div>
            );


      case 3:
        return (
          <Box sx={{ mt: 4 }}>
            <Button
              variant="contained"
              onClick={() => setOpenDescriptionModal(true)}
              fullWidth
              size="large"
            >
              Ajouter une Description
            </Button>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <>

           <Helmet>
        <title>Add a project- I.T Reserch Community | Inter-university scientific club in Algeria</title>
        <meta name="robots" content="noindex, nofollow" />

      </Helmet>
      
      
     
    <Box sx={{ maxWidth: 1200, margin: 'auto', p: 4 }}>
      <Header1 text="New Projet" />
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {renderStepContent(activeStep)}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          variant="outlined"
        >
          Retour
        </Button>
        <Button
          variant="contained"
          onClick={activeStep === steps.length - 1 ? handleSave : handleNext}
          disabled={loading.submit}
        >
          {loading.submit ? <CircularProgress size={24} /> : 
            activeStep === steps.length - 1 ? 'Enregistrer' : 'Suivant'}
        </Button>
      </Box>

      <Dialog 
        open={openDescriptionModal} 
        onClose={() => setOpenDescriptionModal(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Description du Projet</DialogTitle>
        <DialogContent>
          <ReactEditorJS
            defaultValue={formData.description}
            onInitialize={handleInitialize}
            tools={EDITOR_JS_TOOLS}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDescriptionModal(false)}>
            Annuler
          </Button>
          <Button 
            onClick={async () => {
              const savedData = await editorCore.current.save();
              setFormData(prev => ({ ...prev, description: savedData }));
              setOpenDescriptionModal(false);
            }}
            variant="contained"
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </Box> </>
  );
};

export default AddProject;