// Table.js
import React from 'react';

const Table = ({ content, withHeadings }) => {
  return (
    <table className="editorjs-content-table">
      <tbody>
        {content.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex} style={{ fontWeight: withHeadings && rowIndex === 0 ? 'bold' : 'normal' }}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
