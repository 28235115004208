import React, { useState, useEffect } from 'react';
import { Button, Box, Card, CardContent, Typography, Pagination, Stack, Chip, IconButton, CircularProgress } from '@mui/material';
import { Language as LanguageIcon, CalendarToday as CalendarIcon, 
         Person as PersonIcon, School as SchoolIcon, LocalLibrary as LibraryIcon } from '@mui/icons-material';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MenuBookIcon from '@mui/icons-material/MenuBook';


const MotionCard = motion(Card);

const EbookList = () => {
  const [ebooks, setEbooks] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

  const handleReadBook = (ebook) => {
    // On encode l'URL pour éviter les problèmes avec les caractères spéciaux
    const encodedPdfUrl = encodeURIComponent(`https://cdn.datalgeria.com/ebooks/files/${ebook.file}.pdf`);
    navigate(`/dashboard/book/${encodedPdfUrl}`);
  };

  const fetchEbooks = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.datalgeria.com/ebooks?page=${page}&limit=6`);
      setEbooks(response.data.ebooks);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching ebooks:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEbooks();
  }, [page]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <Box sx={{ 
      padding: { xs: 2, md: 4 },
      minHeight: '100vh'
    }}>
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 4, 
          textAlign: 'center',
          fontWeight: 'bold',
          color: '#1a237e'
        }}>
        <LibraryIcon sx={{ mr: 1, verticalAlign: 'bottom' }} />
        Bibliothèque Numérique
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress size={60} />
        </Box>
      ) : (
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
        >
          <Stack spacing={4}>
            {ebooks.map((ebook) => (
              <MotionCard
                key={ebook.id}
                variants={item}
                sx={{
                  display: 'flex',
                  overflow: 'hidden',
                  bgcolor: '#f1f1f1',

                  borderRadius: 2,
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    transition: 'transform 0.3s ease-in-out'
                  }
                }}
              >
                <Box
                  sx={{
                    width: 240,
                    height: 340,
                    flexShrink: 0,
                    bgcolor: 'grey.100',
                    position: 'relative'
                  }}
                >
                  <img
                    src={`https://cdn.datalgeria.com/ebooks/covers/${ebook.id}.${ebook.cover_extension}`}
                    alt={ebook.title}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </Box>

                <CardContent sx={{ flex: 1, p: 3 }}>
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#1a237e' }}>
                    {ebook.title}
                  </Typography>

                  <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                    <Chip 
                      icon={<PersonIcon />} 
                      label={ebook.authors} 
                      size="small" 
                      color="primary" 
                      variant="outlined"
                    />
                    <Chip 
                      icon={<SchoolIcon />} 
                      label={ebook.category} 
                      size="small" 
                      color="secondary" 
                      variant="outlined"
                    />
                  </Stack>

                  <Typography variant="body1" sx={{ mb: 2, color: 'text.secondary' }}>
                    {ebook.description}
                  </Typography>

                  <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Box display="flex" alignItems="center">
                      <LanguageIcon sx={{ mr: 1, color: 'text.secondary' }} />
                      <Typography variant="body2" color="text.secondary">
                        {ebook.language.toUpperCase()}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <CalendarIcon sx={{ mr: 1, color: 'text.secondary' }} />
                      <Typography variant="body2" color="text.secondary">
                        {new Date(ebook.date).getFullYear()}
                      </Typography>
                    </Box>
                  </Stack>

                  <Box display="flex" alignItems="center" sx={{ mt: 'auto' }}>
                    <Box
                      component="img"
                      src={ebook.user_picture}
                      alt={ebook.user_name}
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        mr: 2,
                        border: '2px solid #e3f2fd'
                      }}
                    />
                    <Typography variant="subtitle2">
                      {ebook.user_name} {ebook.user_fname}
                    </Typography>
                  </Box>
                  <Button
      variant="contained"
      color="primary"
      onClick={() => handleReadBook(ebook)}
      startIcon={<MenuBookIcon />}
    >
      Lire le livre
    </Button>
                </CardContent>
              </MotionCard>
            ))}
          </Stack>
        </motion.div>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
          size="large"
          sx={{
            '& .MuiPaginationItem-root': {
              fontSize: '1.1rem'
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default EbookList;