import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Box,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BuildIcon from '@mui/icons-material/Build';
import RenderModal from 'components/editor/RenderModal';
import LockIcon from '@mui/icons-material/Lock';

const Sidebar = ({
  setPage,
  selectedStep,
  setSelectedStep,
  setSelectedStepName,
  setSelectedStepUsers,
  allOpen,
  handleToggleAll,
  projectData,
  openSections,
  handleStepClick,
  handleToggle,
  handleOpenModal,
  loading,
  isManager
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');
  const [hoveredSection, setHoveredSection] = useState(null);

  const handleDescriptionModalClose = () => {
    setDescriptionModalOpen(false);
  };

  const handleDescriptionModalOpen = (description) => {
    setCurrentDescription(description);
    setDescriptionModalOpen(true);
  };

  // Animation variants
  const listItemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  };

  const collapseVariants = {
    hidden: { height: 0, opacity: 0 },
    visible: { 
      height: 'auto', 
      opacity: 1,
      transition: { 
        duration: 0.3,
        type: 'spring',
        stiffness: 300,
        damping: 24
      }
    }
  };

  return (
    <Box
      sx={{
        width: 280,
        borderRight: '1px solid rgba(0, 0, 0, 0.08)',
        background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '100vh',
        boxShadow: '4px 0px 15px rgba(0, 0, 0, 0.05)',
      }}
    >
      <AppBar 
        position="static" 
        elevation={0}
        sx={{ 
          background:  '#0a202d',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
        }}
      >
        <Toolbar>
          <BusinessCenterIcon sx={{ mr: 1.5 }} />
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Project Menu
          </Typography>
        </Toolbar>
      </AppBar>

      <List sx={{ 
        opacity: loading ? 0.2 : 1,
        transition: 'opacity 0.3s ease'
      }}>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.1
              }
            }
          }}
        >
          {/* Menu Items with Icons */}
          <motion.div variants={listItemVariants}>
            <ListItem 
              button 
              onClick={() => setPage('overview')}
              sx={{
                my: 0.5,
                borderRadius: 1,
                mx: 1,
                '&:hover': {
                  background: 'rgba(25, 118, 210, 0.08)',
                }
              }}
            >
              <DashboardIcon sx={{ mr: 2, color: '#0a202d' }} />
              <ListItemText primary="Overview" />
            </ListItem>
          </motion.div>

          <motion.div variants={listItemVariants}>
            <ListItem 
              button 
              onClick={() => setPage('resources')}
              sx={{
                my: 0.5,
                borderRadius: 1,
                mx: 1,
                '&:hover': {
                  background: 'rgba(25, 118, 210, 0.08)',
                }
              }}
            >
              <BuildIcon sx={{ mr: 2, color: '#0a202d' }} />
              <ListItemText primary="Resources" />
            </ListItem>
          </motion.div>

          {/* Project Management Section */}
          <motion.div variants={listItemVariants}>
            <ListItem 
              button 
              onClick={handleToggleAll}
              sx={{
                my: 0.5,
                borderRadius: 1,
                mx: 1,
                background: allOpen ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
                '&:hover': {
                  background: 'rgba(25, 118, 210, 0.12)',
                }
              }}
            >
              <BusinessCenterIcon sx={{ mr: 2, color: '#0a202d' }} />
              <ListItemText primary="Project Management" />
              <motion.div
                animate={{ rotate: allOpen ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                <ExpandMoreIcon />
              </motion.div>
            </ListItem>
          </motion.div>

          <AnimatePresence>
            {allOpen && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={collapseVariants}
              >
                {projectData.sections.map((section, titleIndex) => (
                  <Box key={titleIndex}>
                    <ListItem 
                      button 
                      onClick={() => handleToggle(titleIndex)}
                      onMouseEnter={() => setHoveredSection(titleIndex)}
                      onMouseLeave={() => setHoveredSection(null)}
                      sx={{
                        pl: 4,
                        borderRadius: 1,
                        mx: 1,
                        my: 0.5,
                  
                      }}
                    >
                      <ListItemText 
                        primary={section.name}
  

                        sx={{
                          '& .MuiTypography-root': {
                            fontWeight: 500,
                            color: '#2c3e50'
                          },
                          whiteSpace: 'nowrap', // Prevent text wrapping
                          overflow: 'hidden',   // Hide overflowed text
                          textOverflow: 'ellipsis', // Display ellipsis
                          '&:focus': {
                            color: 'primary.main', // Change color on focus
                            textDecoration: 'underline', // Underline on focus
                          },
                        }}


                      />
                                         {!section.hasRight && (
                                  <IconButton size="small" sx={{ color: '#0a202d' }}>
                                    <LockIcon fontSize="small" />
                                  </IconButton>
                                )}
                                              {section.hasRight && (

                      <motion.div
                        initial={false}
                        animate={{ opacity: hoveredSection === titleIndex ? 1 : 0.5 }}
                      >
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDescriptionModalOpen(section.description);
                          }}
                          size="small"
                          sx={{ 
                            mr: 0.5,
                            color: '#0a202d',
                          }}
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </motion.div>
                        )}

                      {isManager && (
                        <motion.div
                          initial={false}
                          animate={{ opacity: hoveredSection === titleIndex ? 1 : 0.5 }}
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenModal('subtitle', section.id, section.users);
                            }}
                            size="small"
                            sx={{ color: '#0a202d' }}
                          >
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </motion.div>
                      )}

{section.hasRight && (
                      <motion.div
                        animate={{ rotate: openSections[titleIndex] ? 180 : 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <ExpandMoreIcon />
                      </motion.div>
                    )}
                    </ListItem>

                    <AnimatePresence>
                      {openSections[titleIndex] && (
                        <motion.div
                          initial="hidden"
                          animate="visible"
                          exit="hidden"
                          variants={collapseVariants}
                        >
                          <List component="div" disablePadding>
                          {section.steps && section.steps.map((step, stepIndex) => (
                                                          <ListItem
                                key={stepIndex}
                                button
                                onClick={() => {

                                  if (!step.hasRight) {
                                    return;
                                  }
                                  setPage('tasks');
                                  setSelectedStep(step.id);
                                  setSelectedStepName(step.name);
                                  setSelectedStepUsers(step.users);
                                }}
                                sx={{
                                  pl: 6,
                                  mx: 2,
                                  my: 0.5,
                                  borderRadius: 1,
                                  background: step.id === selectedStep 
                                    ? 'linear-gradient(135deg, #e3f2fd 0%, #bbdefb 100%)'
                                    : 'transparent',
                                  '&:hover': {
                                    background: step.id === selectedStep
                                      ? 'linear-gradient(135deg, #bbdefb 0%, #90caf9 100%)'
                                      : 'rgba(25, 118, 210, 0.08)',
                                  },
                                }}
                              >
                                <ListItemText 
                                  primary={step.name} 
                                  primaryTypographyProps={{ 
                                    variant: 'body2',
                                    sx: {
                                      color: step.id === selectedStep ? '#1565c0' : '#546e7a',
                                      fontWeight: step.id === selectedStep ? 500 : 400,
                                      whiteSpace: 'nowrap', // Prevent text wrapping
                                      overflow: 'hidden',   // Hide overflowed text
                                      textOverflow: 'ellipsis', // Display ellipsis
                                      '&:focus': {
                                        color: 'primary.main', // Change color on focus
                                        textDecoration: 'underline', // Underline on focus
                                      },
                                    }
                                  }} 
                                />
                                {!step.hasRight && (
                                  <IconButton size="small" sx={{ color: '#0a202d' }}>
                                    <LockIcon fontSize="small" />
                                  </IconButton>
                                )}
                                {step.hasRight && (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDescriptionModalOpen(step.description);
                                  }}
                                  size="small"
                                  sx={{ 
                                    ml: 1,
                                    color: '#0a202d',
                                    opacity: 0.7,
                                    '&:hover': {
                                      opacity: 1,
                                    }
                                  }}
                                >
                                  <InfoIcon fontSize="small" />
                                </IconButton>
                                )}
                              </ListItem>
                            ))}
                          </List>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </Box>
                ))}
                {isManager && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                      <IconButton 
                        onClick={() => handleOpenModal('title')} 
                        sx={{
                          color: '#0a202d',
                          '&:hover': {
                            background: 'rgba(25, 118, 210, 0.08)',
                          }
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </motion.div>
                )}
              </motion.div>
            )}
          </AnimatePresence>

          <motion.div variants={listItemVariants}>
            <ListItem 
              button 
              onClick={() => setPage('files')}
              sx={{
                my: 0.5,
                borderRadius: 1,
                mx: 1,
                '&:hover': {
                  background: 'rgba(25, 118, 210, 0.08)',
                }
              }}
            >
              <FolderIcon sx={{ mr: 2, color: '#0a202d' }} />
              <ListItemText primary="File Management" />
            </ListItem>
          </motion.div>
        </motion.div>
      </List>

      <RenderModal
        data={currentDescription}
        open={descriptionModalOpen}
        onClose={handleDescriptionModalClose}
      />
    </Box>
  );
};

export default Sidebar;