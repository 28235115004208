import React from 'react';


import University from 'components/dashboard/charts/University';
import { Helmet } from 'react-helmet-async';

const Dashboard = () => {
  const university =  localStorage.getItem('university') ;
  
  return (
    <>
       <Helmet>
        <title>Dashboard - I.T Reserch Community | Inter-university scientific club in Algeria</title>
        <meta name="robots" content="noindex, nofollow" />

      </Helmet>
      <div>
      <University
      universityId = {university}
      />
    </div>
    </>

  );
};

export default Dashboard;