import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Chip,
  IconButton,
  Avatar
} from '@mui/material';
import { School, LocationOn, CalendarToday, Category, AccountBalance, Close, Groups } from '@mui/icons-material';
import axios from 'axios';
import { useSpring, animated } from 'react-spring';
import { styled } from '@mui/material/styles';
import { Tree, TreeNode } from 'react-organizational-chart';
import UserDialog from 'components/dashboard/UserDialog'; // Import the UserDialog component

// Styled components for the tree structure
const MemberCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

  
  width: '100%',
  transition: 'all 0.3s',
}));

const StyledAvatar = styled(Avatar)(({ theme, role }) => ({
  width: role === 'president' ? 80 : role === 'vp' ? 70 : 60,
  height: role === 'president' ? 80 : role === 'vp' ? 70 : 60,
  border: `3px solid ${role === 'president' ? theme.palette.error.main :
    role === 'vp' ? theme.palette.primary.main : theme.palette.secondary.main}`,
  boxShadow: theme.shadows[4],
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const RoleLabel = styled(Typography)(({ theme, role }) => ({
  fontSize: role === 'president' ? 18 : role === 'vp' ? 16 : 14,
  fontWeight: role === 'president' ? 700 : role === 'vp' ? 600 : 500,
  color: role === 'president' ? theme.palette.error.main :
    role === 'vp' ? theme.palette.primary.main : theme.palette.text.primary,
}));

const UniversityDialog = ({ open, onClose, universityId }) => {
  const [universityData, setUniversityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(['root']);
  const [error, setError] = useState(null);








  
const [openDialog, setOpenDialog] = useState(false);
const [selectedUserId, setSelectedUserId] = useState(null);




const handleOpenDialog = (userId) => {
  setSelectedUserId(userId);
  setOpenDialog(true);
};

const handleCloseDialog = () => {
  setOpenDialog(false);
  setSelectedUserId(null);
};



useEffect(() => {
  if (selectedUserId) {
    handleOpenDialog(selectedUserId);
  }
}, [selectedUserId]);






  const dialogAnimation = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0.9)',
    config: { tension: 300, friction: 20 },
  });

  useEffect(() => {
    if (universityId && open) {
      setLoading(true);
      setError(null);
      axios
        .get(`https://api.datalgeria.com/universities/university?university_id=${universityId}`)
        .then(response => {
          setUniversityData(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des données:', error);
          setError(error.message);
          setLoading(false);
        });
    }
  }, [universityId, open]);

  // Helper function to generate unique IDs
  const generateNodeId = (role, index) => `${role}-${index}`;

  // Role title mapping
  const getRoleTitle = (role) => {
    const roles = {
      'president': 'Président',
      'vp': 'Vice Président',
      'tc': 'Technical Coordinator',
      'em': 'Event Manager',
      'cm': 'Community Manager',
      'lfm': 'Logistics Manager',
    };
    return roles[role] || role;
  };

  // Role color mapping
  const getRoleColor = (role) => {
    const colors = {
      'president': 'error',
      'vp': 'primary',
      'tc': 'secondary',
      'em': 'secondary',
      'cm': 'secondary',
      'lfm': 'secondary',
    };
    return colors[role] || 'default';
  };

  // Organizational structure rendering
  const renderMemberNode = (member, role) => (
    <MemberCard>
      <StyledAvatar
        onClick={() => handleOpenDialog(member.user_id)}
      role={role} src={member.picture} alt={member.name} />
      <Box>
        <RoleLabel role={role} variant="h6">
          {member.name}
        </RoleLabel>
        <Chip size="small" label={getRoleTitle(role)} color={getRoleColor(role)} />
      </Box>
    </MemberCard>
  );

  const renderOrganizationalTree = (members) => {
    if (!members) return null;

    return (
      <Tree label="Core Members">
        {/* President Node */}
        {members.president?.map((president, index) => (
          <TreeNode
            key={generateNodeId('president', index)}
            label={renderMemberNode(president, 'president')}
          >
            {/* VP Node */}
            {members.vp?.map((vp, vpIndex) => (
              <TreeNode
                key={generateNodeId('vp', vpIndex)}
                label={renderMemberNode(vp, 'vp')}
              >
                {/* Technical Coordinator */}
                {members.tc?.map((tc, tcIndex) => (
                  <TreeNode
                    key={generateNodeId('tc', tcIndex)}
                    label={renderMemberNode(tc, 'tc')}
                  />
                ))}

                {/* Event Manager */}
                {members.em?.map((em, emIndex) => (
                  <TreeNode
                    key={generateNodeId('em', emIndex)}
                    label={renderMemberNode(em, 'em')}
                  />
                ))}

                {/* Community Manager */}
                {members.cm?.map((cm, cmIndex) => (
                  <TreeNode
                    key={generateNodeId('cm', cmIndex)}
                    label={renderMemberNode(cm, 'cm')}
                  />
                ))}

                {/* Logistics Manager */}
                {members.lfm?.map((lfm, lfmIndex) => (
                  <TreeNode
                    key={generateNodeId('lfm', lfmIndex)}
                    label={renderMemberNode(lfm, 'lfm')}
                  />
                ))}
              </TreeNode>
            ))}
          </TreeNode>
        ))}
      </Tree>
    );
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
          <CircularProgress size={60} thickness={4} />
        </DialogContent>
      </Dialog>
    );
  }

  if (error) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Alert severity="error" variant="filled">
            Erreur: {error}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (!universityData) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Alert severity="error" variant="filled">
            Université non trouvée ou erreur de chargement
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const { name, wilaya, year, type, authority, has_club, members } = universityData;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth PaperProps={{ component: animated.div, style: dialogAnimation, sx: { minHeight: '80vh' } }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="h4" component="div" gutterBottom>
            <School sx={{ mr: 1, verticalAlign: 'bottom' }} />
            {name}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <Chip icon={<LocationOn />} label={wilaya} />
            <Chip icon={<CalendarToday />} label={`Établi en ${year}`} />
            <Chip icon={<Category />} label={type} />
            <Chip icon={<AccountBalance />} label={authority} />
          </Box>
        </Box>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <Typography variant="h6">Club:</Typography>
            {has_club ? (
              <Chip icon={<Groups />} label="Présent" color="success" />
            ) : (
              <Chip icon={<Groups />} label="Non disponible" color="error" />
            )}
          </Box>
          {renderOrganizationalTree(members)}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fermer</Button>
      </DialogActions>
      <UserDialog open={openDialog} onClose={handleCloseDialog} userId={selectedUserId} />
    </Dialog>
  );
};

export default UniversityDialog;
