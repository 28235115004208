import React, { useState, useRef } from 'react';
import { Box, Typography, Card, CardContent, Stack, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';

const categories = [
  {
    value: 'person',
    label: 'Personne',
    color: '#5c6bc0',
    bgColor: '#e8eaf6',
    description: 'Noms de personnes, prénoms, surnoms',
  },
  {
    value: 'organization',
    label: 'Organisation',
    color: '#ec407a',
    bgColor: '#fce4ec',
    description: 'Entreprises, institutions, organisations',
  },
  {
    value: 'location',
    label: 'Lieu',
    color: '#66bb6a',
    bgColor: '#e8f5e9',
    description: 'Lieux géographiques, adresses, pays',
  },
  {
    value: 'date',
    label: 'Date',
    color: '#ffa726',
    bgColor: '#fff3e0',
    description: 'Dates, périodes, expressions temporelles',
  },
];

const NER = ({ text = "Jean-Paul travaille chez Microsoft à Paris depuis janvier 2024. Marie dirige Google à Londres." }) => {
  const [annotations, setAnnotations] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const textContainerRef = useRef(null);

  const getTextNodeOffset = (node, offset) => {
    const walker = document.createTreeWalker(
      textContainerRef.current,
      NodeFilter.SHOW_TEXT,
      null,
      false
    );
    
    let currentNode;
    let currentOffset = 0;
    
    while ((currentNode = walker.nextNode())) {
      if (currentNode === node) {
        return currentOffset + offset;
      }
      currentOffset += currentNode.textContent.length;
    }
    
    return offset;
  };

  const handleTextSelection = () => {
    const selection = window.getSelection();
    
    if (!selection.rangeCount) return;
    
    if (selectedCategory && selection.toString().trim()) {
      const range = selection.getRangeAt(0);
      
      if (!textContainerRef.current.contains(range.commonAncestorContainer)) {
        return;
      }

      const start = getTextNodeOffset(range.startContainer, range.startOffset);
      const end = getTextNodeOffset(range.endContainer, range.endOffset);

      if (start === end || start < 0 || end > text.length) {
        return;
      }

      const isOverlapping = annotations.some(ann => 
        (start >= ann.start && start < ann.end) ||
        (end > ann.start && end <= ann.end) ||
        (start <= ann.start && end >= ann.end)
      );

      if (!isOverlapping) {
        const newAnnotation = {
          id: Date.now(),
          start,
          end,
          text: text.slice(start, end),
          category: selectedCategory,
        };
        
        setAnnotations(prev => [...prev, newAnnotation].sort((a, b) => a.start - b.start));
      }
    }
    
    selection.removeAllRanges();
  };

  const removeAnnotation = (id) => {
    setAnnotations(prev => prev.filter(ann => ann.id !== id));
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  const renderAnnotatedText = () => {
    let lastIndex = 0;
    const result = [];

    annotations.sort((a, b) => a.start - b.start).forEach((annotation, index) => {
      if (annotation.start > lastIndex) {
        result.push(
          <span key={`text-${index}`}>
            {text.slice(lastIndex, annotation.start)}
          </span>
        );
      }

      const annotationId = `annotation-${annotation.id}`;
      result.push(
        <span key={annotationId} className="annotation-wrapper">
          <span
            className="annotation"
            data-tooltip-id={annotationId}
            data-tooltip-place="top"
            style={{
              backgroundColor: annotation.category.bgColor,
              color: annotation.category.color,
              padding: '2px 8px',
              borderRadius: '4px',
              margin: '0 2px',
              position: 'relative',
              cursor: 'default',
              transition: 'all 0.2s ease',
            }}
          >
            {text.slice(annotation.start, annotation.end)}
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                removeAnnotation(annotation.id);
              }}
              sx={{
                position: 'absolute',
                top: '-8px',
                right: '-8px',
                padding: '2px',
                backgroundColor: 'white',
                border: `1px solid ${annotation.category.color}`,
                opacity: 0,
                transition: 'opacity 0.2s ease',
                '&:hover': {
                  backgroundColor: annotation.category.bgColor,
                },
                '.annotation-wrapper:hover &': {
                  opacity: 1,
                }
              }}
            >
              <CloseIcon sx={{ fontSize: 12, color: annotation.category.color }} />
            </IconButton>
          </span>
        </span>
      );

      lastIndex = annotation.end;
    });

    if (lastIndex < text.length) {
      result.push(<span key="text-end">{text.slice(lastIndex)}</span>);
    }

    return result;
  };

  return (
    <Card sx={{ maxWidth: 800, margin: '20px auto', boxShadow: '0 4px 20px rgba(0,0,0,0.1)', borderRadius: 2 }}>
      <CardContent sx={{ p: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 600, color: '#1a237e' }}>
          Annotateur de texte
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          {categories.map((category) => (
            <motion.div
              key={category.value}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleCategorySelect(category)}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                backgroundColor: selectedCategory === category ? category.bgColor : '#f5f5f5',
                border: `1px solid ${category.color}`,
                color: category.color,
                borderRadius: '20px',
                padding: '8px 16px',
                cursor: 'pointer',
                marginBottom: '8px',
                transition: 'background-color 0.3s ease, transform 0.3s ease',
                transform: selectedCategory === category ? 'scale(1.05)' : 'scale(1)',
                boxShadow: selectedCategory === category ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: '16px',
                  height: '16px',
                  backgroundColor: category.bgColor,
                  borderRadius: '50%',
                  marginRight: '8px',
                }}
              />
              <span>{category.label}</span>
            </motion.div>
          ))}
        </Box>

        <Box
          ref={textContainerRef}
          onMouseUp={handleTextSelection}
          sx={{
            mt: 3,
            p: 3,
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            minHeight: '120px',
            lineHeight: 1.8,
            fontSize: '16px',
            backgroundColor: '#fff',
            cursor: 'text',
            transition: 'border-color 0.2s ease',
            '&:hover': {
              borderColor: '#2196f3',
            },
          }}
        >
          {renderAnnotatedText()}
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', fontWeight: 600 }}>
            Annotations ({annotations.length})
          </Typography>

          <Stack spacing={1.5} sx={{ mt: 2 }}>
            {annotations.map((annotation) => (
              <motion.div
                key={annotation.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 2,
                  borderRadius: 2,
                  backgroundColor: annotation.category.bgColor,
                  boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                }}
              >
                <Typography sx={{ fontSize: 14, color: annotation.category.color }}>
                  {annotation.text}
                </Typography>

                <IconButton
                  size="small"
                  onClick={() => removeAnnotation(annotation.id)}
                  sx={{ color: annotation.category.color }}
                >
                  <DeleteIcon />
                </IconButton>
              </motion.div>
            ))}
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NER;
