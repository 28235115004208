import React, { useRef, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  IconButton,
  Chip,
  CircularProgress,
  Paper,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  AutoFixHigh as WandIcon,
  Close as CloseIcon,
  Image as ImageIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_JS_TOOLS, ARTICLE_ID } from './editorTools';
import Cropper from 'react-cropper';
import "cropperjs/dist/cropper.css";


const EditorJS = createReactEditorJS();

const ArticleEditor = () => {
  const editorCore = useRef(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [articleType, setArticleType] = useState('');
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState('');
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const cropperRef = useRef(null);

  // Configuration initiale de l'éditeur
  const initialData = {
    time: Date.now(),
    blocks: [
     
    ],
    version: '2.22.2',
  };

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCoverImage(URL.createObjectURL(file));
      setIsCropperOpen(true);
    }
  };

  const handleCrop = () => {
    const cropper = cropperRef.current.cropper;
    const croppedDataURL = cropper.getCroppedCanvas().toDataURL();
    setCroppedImage(croppedDataURL);
    setIsCropperOpen(false);
  };


  const handleTagInputChange = (e) => {
    if (e.key === ' ' && currentTag.trim()) {
      setTags([...tags, currentTag.trim()]);
      setCurrentTag('');
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const generateArticle = async () => {
    if (!title) {
      setSnackbar({ open: true, message: 'Please enter a title first', severity: 'warning' });
      return;
    }

    setIsGenerating(true);
    try {
      const response = await fetch('https://api.datalgeria.com/cm/article', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ title }),
      });

      const data = await response.json();

      if (data && editorCore.current) {
        setDescription(data.description);
        await editorCore.current.render({ time: Date.now(), blocks: data.content, version: '2.22.2' });

        setSnackbar({ open: true, message: 'Article generated successfully!', severity: 'success' });
      }
    } catch (error) {
      console.error('Generation error:', error);
      setSnackbar({ open: true, message: 'Error generating article', severity: 'error' });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSave = async () => {
    try {
      const savedData = await editorCore.current.save();
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('content', JSON.stringify(savedData));
      formData.append('tags', JSON.stringify(tags));
      formData.append('article_type', articleType);
      formData.append('article_pre_id', ARTICLE_ID);
  
      if (croppedImage) {
        // Convertir l'image base64 en Blob
        const blob = await fetch(croppedImage).then((res) => res.blob());
        formData.append('coverImage', blob, 'coverImage.png'); // Ajoutez un nom de fichier pour l'image
      }
  
      const response = await fetch('https://api.datalgeria.com/cm/articles', {
        
        method: 'POST',
        body: formData,  // Le body contient formData qui gère les fichiers
      });
  
      if (response.ok) {
        setSnackbar({ open: true, message: 'Article saved successfully!', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'Error saving article', severity: 'error' });
      }
    } catch (error) {
      console.error('Save error:', error);
      setSnackbar({ open: true, message: 'Error saving article', severity: 'error' });
    }
  };
  


  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', padding: 3 }}>
      <Card elevation={3}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
              Create New Article
            </Typography>
            <Box>
              <Button
                variant="contained"
                startIcon={<WandIcon />}
                onClick={generateArticle}
                disabled={isGenerating || !title}
                sx={{ mr: 2 }}
              >
                {isGenerating ? <CircularProgress size={24} /> : 'Generate'}
              </Button>
              <Button
                variant="contained"
                color="success"
                startIcon={<SaveIcon />}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>

          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ mb: 3 }}
          />

          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Article Type</InputLabel>
            <Select
              value={articleType}
              onChange={(e) => setArticleType(e.target.value)}
              label="Article Type"
            >
              <MenuItem value="featured">Featured</MenuItem>
              <MenuItem value="standard">Standard</MenuItem>
              <MenuItem value="event">Event</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Tags"
            variant="outlined"
            fullWidth
            value={currentTag}
            onChange={(e) => setCurrentTag(e.target.value)}
            onKeyDown={handleTagInputChange}
            helperText="Press space to add a tag"
            sx={{ mb: 3 }}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
            {tags.map((tag, index) => (
              <Chip key={index} label={tag} onDelete={() => handleTagDelete(tag)} color="primary" />
            ))}
          </Box>

          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mb: 3 }}
          />

          <Paper
            variant="outlined"
            sx={{
              p: 3,
              mb: 3,
              cursor: 'pointer',
              textAlign: 'center',
              bgcolor: 'background.default',
            }}
            onClick={() => document.getElementById('image-upload').click()}
          >
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              hidden
              onChange={handleImageUpload}
            />
            {croppedImage ? (
              <Box sx={{ position: 'relative' }}>
                <img
                  src={croppedImage}
                  alt="Cover"
                  style={{ maxWidth: '100%', maxHeight: 300, objectFit: 'cover' }}
                />
                <IconButton
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCroppedImage(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ py: 4 }}>
                <ImageIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                <Typography color="text.secondary">
                  Click or drag to upload cover image
                </Typography>
              </Box>
            )}
          </Paper>

          <Paper variant="outlined" sx={{ p: 2 }}>
            <EditorJS onInitialize={handleInitialize} tools={EDITOR_JS_TOOLS} defaultValue={initialData} />
          </Paper>
        </CardContent>
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Dialog open={isCropperOpen} onClose={() => setIsCropperOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Crop Image</DialogTitle>
        <DialogContent>
          <Cropper
            ref={cropperRef}
            src={coverImage}
            style={{ height: 400, width: '100%' }}
            aspectRatio={2}
            guides={true}
            viewMode={1}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCropperOpen(false)}>Cancel</Button>
          <Button onClick={handleCrop} variant="contained" color="primary">
            Crop
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ArticleEditor;
