// Quote.js
import React from 'react';

const Quote = ({ text, caption, alignment }) => {
  return (
    <blockquote    className="editorjs-content-quote">
      <p>{text}</p>
      <cite>{caption}</cite>
    </blockquote>
  );
};

export default Quote;
