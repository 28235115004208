import React, { useState, useRef, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Container, Box, Typography, IconButton } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import ScrollArrow from './ScrollArrow';
import Section from './Section';
import AddSectionModal from './AddSectionModal';
import AddTaskModal from './AddTaskModal';
import Loading from 'components/animations/Loading';

const SCROLL_AMOUNT = 300;

const TasksComponent = ({ stepId, stepName, users, projectData }) => {
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [error, setError] = useState(null);
  const [sectionDialogOpen, setSectionDialogOpen] = useState(false);
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [hasRightGlobal, setHasRightGlobal] = useState(false);
  
  const containerRef = useRef(null);

  // use effect, from users in projectData, let only the one who has their id in "users"

  useEffect(() => {
    if (!stepId || !projectData || !projectData.users || !users) return;
  
    console.log(JSON.stringify(projectData.users));
    console.log("kokok", JSON.stringify(users));
  
    // Filtrer projectData.users basé sur les ids présents dans users
    const filteredUsers = projectData.users.filter(user =>
      users.some(u => u.user_id === user.id) // Compare user.id avec u.user_id dans users
    );
  
    // Mettre à jour currentUsers avec les résultats filtrés
    setCurrentUsers(filteredUsers);
    console.log("nononon", JSON.stringify(filteredUsers));
  }, [users, projectData, stepId]);
  



  const updateColumnOrder = async (newColumns) => {
    try {
      const updates = newColumns.map((col, index) => ({
        id: col.id,
        order: index + 1
      }));

      const response = await fetch('https://api.datalgeria.com/project/columns/reorder', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          stepId: stepId,
          updates
        })
      });

      const data = await response.json();
      if (!data.success) {
        throw new Error('Failed to update column order');
      }

      // Recharger les données pour s'assurer de la synchronisation
      await fetchTasks2();
    } catch (error) {
      console.error('Error updating column order:', error);
      // Revert to previous state if needed
      setColumns(prevColumns => [...prevColumns]);
    }
  };

  const updateTaskPosition = async (taskId, sourceColumnId, destColumnId, newOrder) => {
    try {
      const response = await fetch('https://api.datalgeria.com/project/tasks/reorder', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          columnId: sourceColumnId,
          taskId,
          newOrder,
          newColumnId: destColumnId
        })
      });

      const data = await response.json();
      if (!data.success) {
        throw new Error('Failed to update task position');
      }

      // Recharger les données pour s'assurer de la synchronisation
      await fetchTasks2();
    } catch (error) {
      console.error('Error updating task position:', error);
      // Revert to previous state if needed
      setColumns(prevColumns => [...prevColumns]);
    }
  };



















  useEffect(() => {
    if (stepId) {
      fetchTasks();
    }
  }, [stepId]);  // Re-fetch tasks when stepId changes

  const fetchTasks = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      setLoading(true);
      const response = await fetch(`https://api.datalgeria.com/project/tasks/${stepId}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
      });
      const data = await response.json();
      
      if (data.success) {
      setHasRightGlobal(data.hasRightGlobal);
      setColumns(data.columns.sort((a, b) => a.order - b.order));
      } else {
        throw new Error('Failed to fetch tasks');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const fetchTasks2 = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      setLoading2(true);
      const response = await fetch(`https://api.datalgeria.com/project/tasks/${stepId}`, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
        });
        const data = await response.json();
      
      if (data.success) {
        setHasRightGlobal(data.hasRightGlobal);

        setColumns(data.columns.sort((a, b) => a.order - b.order));
      } else {
        throw new Error('Failed to fetch tasks');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading2(false);
    }
  };


  useEffect(() => {
    handleScroll();
    window.addEventListener('resize', handleScroll);
    return () => window.removeEventListener('resize', handleScroll);
  }, []);

  const handleScroll = () => {
    if (!containerRef.current) return;
    
    const container = containerRef.current;
    const hasHorizontalScrollbar = container.scrollWidth > container.clientWidth;
    
    setShowLeftArrow(container.scrollLeft > 0);
    setShowRightArrow(
      hasHorizontalScrollbar && 
      Math.ceil(container.scrollLeft) < container.scrollWidth - container.clientWidth
    );
  };

  const scroll = (direction) => {
    if (!containerRef.current) return;
    
    const container = containerRef.current;
    const scrollAmount = direction === 'left' ? -SCROLL_AMOUNT : SCROLL_AMOUNT;
    
    container.scrollTo({
      left: container.scrollLeft + scrollAmount,
      behavior: 'smooth'
    });
  };

  const onDragEnd = async (result) => {
    const { source, destination, type, draggableId } = result;

    if (!destination) return;

    const newColumns = [...columns];

    if (type === 'section') {
      const [movedSection] = newColumns.splice(source.index, 1);
      newColumns.splice(destination.index, 0, movedSection);
      
      setColumns(newColumns); // Optimistic update
      await updateColumnOrder(newColumns);
      return;
    }

    const sourceColumn = newColumns.find(col => col.id === source.droppableId);
    const destColumn = newColumns.find(col => col.id === destination.droppableId);

    const [movedTask] = sourceColumn.tasks.splice(source.index, 1);
    destColumn.tasks.splice(destination.index, 0, movedTask);

    // Update local state optimistically
    setColumns(newColumns);

    // Make API call
    await updateTaskPosition(
      draggableId,
      source.droppableId,
      destination.droppableId,
      destination.index + 1
    );
  };



  if (loading) {
    return <Box sx={{ p: 4 }}>
      <Loading />
    </Box>;
  }

  if (error) {
    return <Box sx={{ p: 4, color: 'error.main' }}>Error: {error}</Box>;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container maxWidth="lg" sx={{ py: 4, position: 'relative' }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4">Tasks for the step {stepName}


          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
  {hasRightGlobal && (
          <IconButton onClick={() => setSectionDialogOpen(true)}>
            <SettingsIcon />
          </IconButton>
        )}
        </Box>

        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center',
opacity: loading2 ? 0.5 : 1,

        }}>
          <ScrollArrow 
            direction="left" 
            show={showLeftArrow} 
            onClick={() => scroll('left')} 
          />
          <ScrollArrow 
            direction="right" 
            show={showRightArrow} 
            onClick={() => scroll('right')} 
          />

          <Droppable droppableId="board" type="section" direction="horizontal">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={(el) => {
                  provided.innerRef(el);
                  containerRef.current = el;
                }}
                onScroll={handleScroll}
                sx={{
                  display: 'flex',
                  gap: 2,
                  overflowX: 'auto',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': { display: 'none' },
                  px: 2,
                  width: '100%'
                }}
              >
                {columns.map((column, index) => (
                  <Section
                    key={column.id}
                    projectData={projectData}
                    hasRightGlobal={hasRightGlobal}
                    section={{
                      id: column.id,
                      name: column.name,
                      description: column.description,
                      tasks: column.tasks.map(task => ({
                        id: task.id,
                        name: task.name,
                        description: task.description,
                        users: task.users,
                        order: task.order,
                        hasRight: task.hasRight,
                        canMove: task.canMove
                      }))
                    }}
                    index={index}
                    onAddTask={() => {
                      setCurrentSection(column.id);
                      setTaskDialogOpen(true);
                    }}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Box>

        <AddSectionModal
          modalOpen={sectionDialogOpen}
          handleModalClose={() => setSectionDialogOpen(false)}
          users={columns}
          RefreshStep={fetchTasks2}
          stepId={stepId}
          onAdd={async (name) => {
            if (name) {
              // Here you would make API call to create new section
              // const newSection = await createSection(name);
              // setColumns(prev => [...prev, newSection]);
              setSectionDialogOpen(false);
            }
          }}
        />

        <AddTaskModal
          modalOpen={taskDialogOpen}
          handleModalClose={() => setTaskDialogOpen(false)}
          columnId={currentSection}
          RefreshStep={fetchTasks2}
          users={currentUsers}
          onAdd={async (content) => {
            if (content && currentSection) {
              // Here you would make API call to create new task
              // const newTask = await createTask(currentSection, content);
              // setColumns(prev => prev.map(col => 
              //   col.id === currentSection 
              //     ? { ...col, tasks: [...col.tasks, newTask] }
              //     : col
              // ));
              setTaskDialogOpen(false);
            }
          }}
        />
      </Container>
    </DragDropContext>
  );
};

export default TasksComponent;