import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Avatar, Box, CircularProgress, Button } from '@mui/material';

const DiscordCard = ({ discordData, hasDiscord }) => {
  // Si l'utilisateur n'a pas Discord, on affiche la carte d'avertissement
  const [loading, setLoading] = useState(false);


  const handleDiscordConnect = async () => { // Redirection vers l'endpoint de liaison Discord *
    const authToken = localStorage.getItem('authToken');
    setLoading(true);
    try {
      const response = await axios.get('https://api.datalgeria.com/auth/discord/link', {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      if (response.data.success) {
        window.location.href = response.data.authUrl;
      } else {
        console.error('Failed to link Discord account.');
      }
    } catch (error) {
      console.error('Error linking Discord account:', error);
    } finally {
      setLoading(false);
    }
  }


  if (!hasDiscord) {
    return (
      <Card sx={{ width: 300, mt: 3, mb: 3, borderRadius: 2, boxShadow: 3, backgroundColor: '#292b2f' }}>
        <Box
          sx={{
            height: 120,
            backgroundColor: '#2f3136',
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
          }}
        />
        <CardContent sx={{ position: 'relative' }}>
          <Avatar
            alt="Discord Avatar"
            src="https://via.placeholder.com/120?text=No+Avatar"
            sx={{
              width: 120,
              height: 120,
              border: '3px solid #202225',
              position: 'absolute',
              top: '0',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
          <Box sx={{ mt: 6, textAlign: 'center' }}>
            <Typography color="#f44336" variant="h6" fontWeight="bold">
              You must link your ITRC account with Discord
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: 'none', mt: 2 }}
              disabled={loading}
              onClick={handleDiscordConnect}
            >
              Connect with Discord
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  }

  // Si l'utilisateur a Discord, on affiche sa carte normale
  const { discord_username, discord_name, discord_avatar, discord_banner } = discordData;
  const displayName = discord_name || discord_username;
  const avatarFallback = discord_avatar || 'https://via.placeholder.com/120?text=No+Avatar';
  const bannerFallback = discord_banner || 'https://via.placeholder.com/300x120/202225/ffffff?text=No+Banner';

  return (
    <Card sx={{ width: 300, mt: 3, mb: 3, borderRadius: 2, boxShadow: 3, backgroundColor: '#292b2f' }}>
      <Box
        sx={{
          height: 120,
          backgroundImage: `url(${bannerFallback})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: !discord_banner ? '#2f3136' : 'transparent',
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
        }}
      />
      <CardContent sx={{ position: 'relative' }}>
        <Avatar
          alt="Discord Avatar"
          src={avatarFallback}
          sx={{
            width: 120,
            height: 120,
            border: '3px solid #202225',
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Typography color="#ccc" variant="h6" fontWeight="bold">
            {displayName}
          </Typography>
          <Typography color="#ccc" variant="body2">
            {discord_username}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const DiscordContainer = ({ userId }) => {
  const [discordData, setDiscordData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDiscordData = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await axios.post('https://api.datalgeria.com/club/checkDiscord', {
          user_id: userId
        }, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });

        setDiscordData(response.data);
      } catch (err) {
        setError('Error fetching Discord data.');
      } finally {
        setLoading(false);
      }
    };

    fetchDiscordData();
  }, [userId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  if (!discordData) return null;

  const { success, data } = discordData;
  const {has_discord, is_member_of_guild, message } = data;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 3,
        p: 2,
        flexWrap: 'wrap',
      }}
    >
      <DiscordCard discordData={data} hasDiscord={has_discord} />
      <Box sx={{ maxWidth: { xs: '100%', md: '500px' }, flex: 1 }}>
        <Typography variant="h5" sx={{ color: '#191919', mb: 2 }}>
          Communication Server
        </Typography>
        <Typography variant="body1" sx={{ color: '#191919', mb: 3 }}>
          Our club communicates through Discord to keep everything synchronized between our platform and the Discord server.
          This ensures all members have real-time access to the latest updates and announcements. It's an essential tool for seamless communication and collaboration.
        </Typography>

        {has_discord && is_member_of_guild && (
          <Typography variant="body2" sx={{ color: '#00ff00', mb: 2 }}>
            {message}
          </Typography>
        )}
        
        {has_discord && !is_member_of_guild && (
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: 'none' }}
            onClick={() => window.open('https://discord.gg/itrc', '_blank')}
          >
            Join Server
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DiscordContainer;