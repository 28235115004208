import React, { useEffect, useState } from 'react';
import { Divider, Container } from '@mui/material';
import './articles.css';
import Header1 from 'components/headers/Header1';

const Articles = () => {
  const [articles, setArticles] = useState(null);

  useEffect(() => {
    // Fetch the API data
    fetch('https://api.datalgeria.com/cm/articles?type=featured&a=551')
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setArticles(data.data); // Set the fetched articles data
        }
      });
  }, []);

  // Return loading state if data is not yet loaded
  if (!articles) {
    return <p>Loading...</p>;
  }

  return (
    <Container 
    className="icontainer"
    sx={{ mx: 'auto', padding: '2rem'}}>
                    <Divider sx={{ marginBottom: '2rem' }} />

      <section className="Articles background">
        <div className="cc">
          <div className="heading f_flex">
            <i className="fa fa-newspaper"></i>
            <Header1 text="Featured Articles" />
          </div>

          <div 
          style={{
            height: '500px',
          }}
          className="container3">
            {articles.map((article, index) => (
              <div
                key={article.id}
                className={`article article-${index + 1}`}
                style={{
                  gridArea: index === 0
                    ? 'A'
                    : index === 1
                    ? 'B'
                    : index === 2
                    ? 'C'
                    : index === 3
                    ? 'D'
                    : 'E',
                }}
              >
                <img
                  src={`https://cdn.datalgeria.com/blog/covers/${article.id}.${article.cover_image}`}
                  alt={article.title}
                />
                <div className="article-content">
                  <h2>{article.title}</h2>
                  <p>{article.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Container>
  );
};

export default Articles;
