import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, Typography, Chip, CircularProgress, LinearProgress, Avatar } from '@mui/material';
import axios from 'axios';
import { useSpring, animated } from 'react-spring';

// Composant UserDialog
const UserDialog = ({ open, onClose, userId }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Animation for dialog entrance
  const animation = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0.9)',
    config: { tension: 300, friction: 30 }
  });

  useEffect(() => {
    if (userId) {
      // Appel à l'API pour récupérer les informations de l'utilisateur
      axios.get(`https://api.datalgeria.com/users/user?user_id=${userId}`)
        .then(response => {
          setUserData(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
          setLoading(false);
        });
    }
  }, [userId]);

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  // Si les données utilisateur ne sont pas disponibles
  if (!userData) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Typography variant="h6" color="error">Utilisateur non trouvé</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  // Destructurer les données utilisateur et du club
  const { username, email, picture, clubInfo } = userData;

  const xp = clubInfo?.xp || 0;
  // Calcul du niveau XP et de la progression
  const level = Math.floor(xp / 1000); // Niveau actuel (par exemple, 6500 xp -> niveau 6)
  const xpInProgress = xp % 1000; // XP restant pour compléter le niveau
  const progress = xpInProgress / 1000; // Progression en pourcentage

  // Helper function to determine the user's role badges
  const renderRoleBadges = () => {
    const roles = [];
    
    if (clubInfo?.is_president) roles.push(<Chip key="president" label="Président" color="primary" />);
    if (clubInfo?.is_vp) roles.push(<Chip key="vp" label="Vice-Président" color="secondary" />);
    if (clubInfo?.is_tc) roles.push(<Chip key="tc" label="Coordinateur Technique" color="success" />);
    if (clubInfo?.is_em) roles.push(<Chip key="em" label="Responsable Événementiel" color="error" />);
    if (clubInfo?.is_cm) roles.push(<Chip key="cm" label="Responsable Communauté" color="warning" />);
    // if vp or tc add GRH badge
    if (clubInfo?.is_lfm) roles.push(<Chip key="lfm" label="Responsable Logistique" color="info" />);
    if (clubInfo?.is_vp || clubInfo?.is_tc) roles.push(<Chip key="grh" label="GRH" color="info" />);
    if (clubInfo?.is_intern) roles.push(<Chip key="intern" label="Stagiaire" color="info" />);
    if (clubInfo?.roles?.includes('teacher')) roles.push(<Chip key="teacher" label="Enseignant" color="warning" />);
    roles.push(<Chip key={clubInfo?.status} label={clubInfo?.status} color="warning" />);
    
    return roles;
  };



  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <animated.div style={animation}>
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={picture} alt={username} sx={{ width: 50, height: 50, marginRight: 2 }} />
            <Typography variant="h6">{username}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* Affichage des informations utilisateur */}
            <Typography variant="body1">Nom : {clubInfo?.name} {clubInfo?.fname}</Typography>
            <Typography variant="body1">Username : {username}</Typography>
            <Typography variant="body1">Email (Club) : {clubInfo?.email}</Typography>

            {/* Affichage des badges */}
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {renderRoleBadges()}
            </Box>


            {/* Affichage du niveau XP */}
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">Niveau XP du Club : {level} (XP total : {xp})</Typography>
              <LinearProgress variant="determinate" value={progress * 100} sx={{ marginTop: 1 }} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Typography variant="caption" color="textSecondary">XP restant : {1000 - xpInProgress} XP</Typography>
                <Typography variant="caption" color="textSecondary">Prochain niveau</Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">Fermer</Button>
        </DialogActions>
      </animated.div>
    </Dialog>
  );
};

export default UserDialog;
