import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditorContent from './EditorContent'; // Import the EditorContent component
import UserSelect from './UserSelect'; // Import UserSelect component

const AddItemModal = ({ modalOpen, handleModalClose, modalType, users, newItemText, setNewItemText, handleSaveItem, projectId, RefreshSideBar }) => {
  const [title, setTitle] = useState(''); // State to hold the title
  const [description, setDescription] = useState(null); // State for editor content
  const [selectedUserIds, setSelectedUserIds] = useState([]); // State for selected user IDs
  const [managerId, setManagerId] = useState(''); // State for manager ID
  const editorRef = useRef(null); // Reference to the EditorContent component
  const [stepUsers, setStepUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // State for select all checkbox

  useEffect(() => {
    if (Array.isArray(users) && Array.isArray(modalType.users)) {
      // Filter users based on whether they are present in modalType.users
      const filteredUsers = users.filter(user =>
        modalType.users.some(selectedUser => selectedUser.user_id === user.id)
      );

      // Set the filtered users to stepUsers
      setStepUsers(filteredUsers);

      // Debug logs
      console.log('Selected Step Users:', modalType.users);
      console.log('Users in AddItemModal:', JSON.stringify(users));
      console.log('Filtered Step Users:', filteredUsers);
      setStepUsers(filteredUsers);
    }
  }, [modalType.users, users]); // Add users and selectedStepUsers as dependencies

  // Function to handle saving the item with title, description, and selected users
  const handleSaveItemWithEditor = async () => {
    let editorOutput = null;

    // Get the editor's JSON output if editorRef is set
    if (editorRef.current) {
      editorOutput = await editorRef.current.handleSaveEditorContent();
    }

    // Determine endpoint and id based on modalType
    const endpoint =
      modalType.type === 'title'
        ? 'https://api.datalgeria.com/project/section'
        : 'https://api.datalgeria.com/project/step';

    const newItem = {
      project_id: projectId,
      id: modalType.type === 'title' ? projectId : modalType.titleIndex,
      name: title, // Title field
      description: editorOutput ? JSON.stringify(editorOutput) : null, // JSON stringified editor output
      userIds: selectedUserIds, // Array of selected user IDs
      managerId: managerId, // Manager ID
    };

    console.log('New Item:', newItem); // Log the new item to check the structure
    try {
      const authToken = localStorage.getItem('authToken'); // Retrieve the auth token from localStorage
      const response = await fetch(endpoint, {
        method: 'POST', // Specify the request method
        headers: {
          'Content-Type': 'application/json', // Specify the content type
          'Authorization': `Bearer ${authToken}`, // Add the Authorization header with the bearer token
        },
        body: JSON.stringify(newItem), // Convert the newItem object to JSON
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json(); // Parse the JSON response
      console.log('Item saved successfully:', result); // Log the result or handle it as needed
    } catch (error) {
      console.error('Error saving item:', error); // Handle any errors
    } finally {
      handleModalClose(); // Close the modal after saving the
      RefreshSideBar(); // Trigger sidebar refresh after adding item
    }
  };

  // Handle select all checkbox change
  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedUserIds(users.map(user => user.id));
    } else {
      setSelectedUserIds([]);
    }
  };

  return (
    <Dialog open={modalOpen} onClose={handleModalClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Add New {modalType.type === 'title' ? 'Section' : 'Step'}
      </DialogTitle>
      <DialogContent>
        {/* Input field for the title */}
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          variant="outlined"
          value={title} // Use the title state
          onChange={(e) => setTitle(e.target.value)} // Update title state on change
        />
        {/* Select All Checkbox */}
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAllChange}
              color="primary"
            />
          }
          label="Select All"
        />
        {/* User selection component */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton>
            <ArrowBackIosIcon />
          </IconButton>
          <UserSelect
            users={modalType.type === 'title' ? users : stepUsers}
            selectedUserIds={selectedUserIds}
            setSelectedUserIds={setSelectedUserIds}
          />
          <IconButton>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
        {/* Manager selection dropdown */}
        <TextField
          select
          label="Manager"
          value={managerId}
          onChange={(e) => setManagerId(e.target.value)}
          SelectProps={{
            native: true,
          }}
          fullWidth
          variant="outlined"
          margin="dense"
        >
          <option value="">Select Manager</option>
          {selectedUserIds.map((userId) => {
            const user = users.find((user) => user.id === userId);
            return (
              <option key={userId} value={userId}>
                {user ? user.name : ''}
              </option>
            );
          })}
        </TextField>
        <Divider sx={{ marginY: 3 }} />
        <h3>Description</h3>
        {/* Editor for the description */}
        <EditorContent
          ref={editorRef} // Reference to EditorContent component
          onContentChange={setDescription} // Set description when content changes
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button onClick={handleSaveItemWithEditor} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemModal;
