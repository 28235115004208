import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import { motion } from "framer-motion";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const ProfilePage = () => {
  const [bio, setBio] = useState("Ceci est ma bio par défaut.");
  const [editBio, setEditBio] = useState(false);
  const [photo, setPhoto] = useState(
    "https://cdn.discordapp.com/avatars/618394284542132224/d0fd3f5ff7d8b8469a1cc8229c2be5cb.png"
  );
  const [newPhoto, setNewPhoto] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleBioChange = (event) => {
    setBio(event.target.value);
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        alert("La taille de l'image ne doit pas dépasser 2MB.");
        return;
      }
      if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
        alert("Le fichier doit être au format PNG, JPG ou JPEG.");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setNewPhoto(e.target.result); // Prévisualisation de la photo
        setImageLoaded(false); // Réinitialisation pour l'effet fade
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("bio", bio);
    if (newPhoto) {
      formData.append("photo", newPhoto); // Ajout de la nouvelle photo si elle existe
    }

    try {
      const response = await fetch("https://example.com/api/profile", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("Profil mis à jour avec succès !");
        if (newPhoto) {
          setPhoto(newPhoto); // Mise à jour de l'avatar
          setNewPhoto(null); // Réinitialisation
        }
        setEditBio(false); // Fin de l'édition de la bio
      } else {
        alert("Erreur lors de la mise à jour du profil.");
      }
    } catch (error) {
      console.error("Erreur réseau :", error);
      alert("Une erreur s'est produite.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 4,
        padding: 4,
        bgcolor: "#f4f6f8",
        height: "100vh",
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: "bold", color: "#333" }}>
        Mon Profil
      </Typography>

      <Paper elevation={3} sx={{ padding: 4, borderRadius: 4, maxWidth: 600 }}>
        {/* Avatar Section */}
        <Box sx={{ position: "relative", textAlign: "center", mb: 4 }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: imageLoaded ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <Avatar
              alt="User Avatar"
              src={newPhoto || photo}
              sx={{ width: 140, height: 140, margin: "auto" }}
              onLoad={() => setImageLoaded(true)}
            />
          </motion.div>
          <IconButton
            color="primary"
            sx={{
              position: "absolute",
              bottom: 0,
              right: "calc(50% - 20px)",
              bgcolor: "white",
              boxShadow: 2,
            }}
            component="label"
          >
            <CameraAltIcon />
            <input
              type="file"
              accept="image/png, image/jpeg"
              hidden
              onChange={handlePhotoChange}
            />
          </IconButton>
        </Box>

        {/* Bio Section */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
            Bio
          </Typography>
          <TextField
            fullWidth
            value={bio}
            onChange={handleBioChange}
            multiline
            rows={3}
            variant="outlined"
            placeholder="Ajoutez une bio..."
          />
        </Box>

        {/* Save Button */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSave}
          sx={{
            mt: 2,
            fontWeight: "bold",
            textTransform: "uppercase",
            bgcolor: "#007BFF",
          }}
        >
          Enregistrer
        </Button>
      </Paper>
    </Box>
  );
};

export default ProfilePage;
