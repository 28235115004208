import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Members = () => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [typeFilter, setTypeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const authToken = localStorage.getItem('authToken');

  const fetchMembers = async (page = 1, search = '', type = '', status = '') => {
    try {
      setLoading(true);
      const response = await axios.get('https://api.datalgeria.com/club/getMembers', {
        params: { page, search, type, status },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data.success) {
        setMembers(response.data.data);
        setTotal(response.data.total);
      } else {
        setSnackbarMessage('Failed to fetch members.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error fetching members:', error);
      setSnackbarMessage('Error fetching members.');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMembers(page + 1, search, typeFilter, statusFilter);
  }, [page, search, typeFilter, statusFilter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleEditClick = (member) => {
    setCurrentMember(member);
    setEditDialogOpen(true);
  };

  const handleStatusChange = async (newStatus) => {
    try {
      const response = await axios.post(
        'https://api.datalgeria.com/club/updateStatus',
        { id: currentMember.id, status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.success) {
        setSnackbarMessage('Status updated successfully.');
        setSnackbarOpen(true);
        fetchMembers(page + 1, search, typeFilter, statusFilter);
      } else {
        setSnackbarMessage('Failed to update status.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error updating status:', error);
      setSnackbarMessage('Error updating status.');
      setSnackbarOpen(true);
    } finally {
      setEditDialogOpen(false);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Club Members
      </Typography>

      <Box display="flex" alignItems="center" mb={2} gap={2}>
        <TextField
          variant="outlined"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size="small"
          sx={{ flex: 1 }}
        />
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="student">Student</MenuItem>
            <MenuItem value="teacher">Teacher</MenuItem>
            <MenuItem value="partner">Partner</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="intern">Intern</MenuItem>
            <MenuItem value="member">Member</MenuItem>
            <MenuItem value="staff">Staff</MenuItem>
          </Select>
        </FormControl>
        <IconButton color="primary" onClick={() => fetchMembers(page + 1, search)}>
          <SearchIcon />
        </IconButton>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Picture</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>
                    <img
                      src={member.picture}
                      alt="profile"
                      style={{ width: 50, height: 50, borderRadius: '50%' }}
                    />
                  </TableCell>
                  <TableCell>{member.username}</TableCell>
                  <TableCell>{member.name}</TableCell>
                  <TableCell>{member.type}</TableCell>
                  <TableCell>{member.status}</TableCell>
                  <TableCell>
                    <IconButton color="info" onClick={() => handleEditClick(member)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Status</DialogTitle>
        <DialogContent>
          <Typography>
            Change status for <b>{currentMember?.username}</b>
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={currentMember?.status || ''}
              onChange={(e) => handleStatusChange(e.target.value)}
            >
              <MenuItem value="intern">Intern</MenuItem>
              <MenuItem value="member">Member</MenuItem>
              <MenuItem value="staff">Staff</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Members;
