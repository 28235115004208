import React, { useState } from 'react';
import { Box, Typography, Paper, IconButton } from '@mui/material';
import { motion } from "framer-motion";
import { styled } from '@mui/material/styles';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const ProjectRoadmap = ({ projectData }) => {
  const sections = projectData?.sections || [];

  const [currentIndex, setCurrentIndex] = useState(0); // Manage the index of the user list

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const sectionVariants = {
    hidden: { 
      opacity: 0,
      y: 50
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15,
        staggerChildren: 0.2
      }
    }
  };

  const stepVariants = {
    hidden: { 
      opacity: 0,
      x: -20
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  // Navigate to previous or next users
  const handlePrev = () => {
    setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => Math.min(prevIndex + 1, sections[currentIndex]?.steps[0]?.users.length - 1));
  };

  return (
    <Box
      component={motion.div}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      sx={{
        width: '100%',
        p: 4,
        overflowX: 'auto',
        bgcolor: 'background.default'
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
        {sections.map((section, sectionIndex) => (
          <Box
            key={section.id}
            component={motion.div}
            variants={sectionVariants}
            sx={{ position: 'relative' }}
          >
            {/* Section Header */}
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                mb: 3
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  bgcolor: '#0a202d',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontWeight: 'bold'
                }}
              >
                {sectionIndex + 1}
              </Box>
              <Typography 
                variant="h5" 
                sx={{ 
                  ml: 2,
                  color: '#0a202d',
                  fontWeight: 'bold'
                }}
              >
                {section.name}
              </Typography>
            </Box>

            {/* Steps Container */}
            <Box sx={{ position: 'relative', pl: 3 }}>
              {/* Vertical Connection Line */}
              <Box
                sx={{
                  position: 'absolute',
                  left: 24,
                  top: 0,
                  bottom: 0,
                  width: 2,
                  bgcolor: '#0a202d',
                  opacity: 0.5
                }}
              />

              {/* Steps */}
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 3,
                  pl: 5
                }}
              >
                {section.steps && section.steps.map((step) => (
                  <Box
                    key={step.id}
                    component={motion.div}
                    variants={stepVariants}
                    sx={{
                      position: 'relative',
                      width: 280
                    }}
                  >
                    {/* Horizontal Connection Line */}
                    <Box
                      sx={{
                        position: 'absolute',
                        left: -40,
                        top: '50%',
                        width: 40,
                        height: 2,
                        bgcolor: '#0a202d',
                        opacity: 0.5
                      }}
                    />

                    <StyledPaper 
                      elevation={3}
                      sx={{
                        borderLeft: 4,
                        borderColor: '#0a202d'
                      }}
                    >
                      <Typography variant="h6" sx={{ color: '#0a202d' }}>
                        {step.name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#0a202d', mt: 1 }}>
                        {step.description && JSON.parse(step.description).blocks.map(block => block.data.text).join(' ')}
                      </Typography>

                      {/* Users with horizontal scroll and arrows */}
                      <Box sx={{ mt: 2, position: 'relative' }}>
                        <IconButton
                          onClick={handlePrev}
                          disabled={currentIndex === 0}
                          sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}
                        >
                          <ArrowBackIos />
                        </IconButton>

                        <Box sx={{ display: 'flex', overflowX: 'auto', gap: 2 }}>
                          {step.users.slice(currentIndex, currentIndex + 3).map((user, index) => {
                            const userDetails = projectData.users.find(u => u.id === user.user_id);
                            return (
                              <Box key={user.user_id} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                  src={userDetails?.picture || ''}
                                  alt={`${userDetails?.name} ${userDetails?.fname}`}
                                  sx={{ width: 40, height: 40, marginRight: 1 }}
                                />
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                  {userDetails?.name} {userDetails?.fname}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>

                        <IconButton
                          onClick={handleNext}
                          disabled={currentIndex + 3 >= step.users.length}
                          sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      </Box>
                    </StyledPaper>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ProjectRoadmap;
