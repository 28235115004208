import React, { useState } from "react";
import { IconButton, Box, Typography } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import Ner from "components/dashboard/tasks/NER";
import SentimentAnalysis from "components/dashboard/tasks/SentimentAnalysis";
import IsTranslationTrue from "components/dashboard/tasks/IsTranslationTrue";

const DailyTasks = () => {
  const tasks = [
    { id: 1, text: "Jean-Paul travaille chez Microsoft à Paris.", type: "ner" },
    { id: 2, text: "Je suis très content de mon expérience aujourd'hui !", type: "sentiment" },
    { id: 3, text: "Marie dirige Google à Londres depuis janvier.", type: "ner" },
    { id: 4, text: "Ce produit est vraiment mauvais, je ne le recommande pas.", type: "sentiment" },
    { id: 5, text: "كيف حالك؟", translation: "How are you?", type: "translation" },
    { id: 6, text: "أنا سعيد جدًا.", translation: "I am very happy.", type: "translation" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % tasks.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + tasks.length) % tasks.length);
  };

  const renderTask = () => {
    const currentTask = tasks[currentIndex];
    if (currentTask.type === "ner") {
      return <Ner text={currentTask.text} />;
    } else if (currentTask.type === "sentiment") {
      return <SentimentAnalysis text={currentTask.text} />;
    } else if (currentTask.type === "translation") {
      return (
        <IsTranslationTrue
          text={currentTask.text}
          translation={currentTask.translation}
        />
      );
    }
    return null;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding={3}
      borderRadius="8px"
      maxWidth="80%"
      height="100%"
      margin="auto"
    >
      <Typography variant="h6" gutterBottom>
        Daily Tasks
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <IconButton onClick={handlePrev} aria-label="Previous Task">
          <ArrowBack />
        </IconButton>
        <Box flex="1" display="flex" justifyContent="center" paddingX={2}>
          {renderTask()}
        </Box>
        <IconButton onClick={handleNext} aria-label="Next Task">
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DailyTasks;
