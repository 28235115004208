// RawHTML.js
import React from 'react';

const RawHTML = ({ html }) => {
  return <div 
  className="editorjs-content-raw-html"

  dangerouslySetInnerHTML={{ __html: html }} />;
};

export default RawHTML;
