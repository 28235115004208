import React, { useState, useRef, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Box, Button, Autocomplete, Checkbox, FormControlLabel, TextField, RadioGroup, Radio, FormLabel, CircularProgress, 
    Select, MenuItem, InputLabel, LinearProgress, Typography, FormControl } from "@mui/material";
import "./style.css";
import communes from '../../helpers/communes.json'; // Import the JSON data
import { AccountCircle, AlternateEmail, Phone } from '@mui/icons-material';
import Input1 from '../../components/inputs/Input1';
import TextArea1 from '../../components/inputs/TextArea1';
import TagSelector from '../../components/global/TagSelector';
import Universities from "../../components/global/Universities";
import joinClub from 'api/club/join';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Helmet } from 'react-helmet-async';

const Join = () => {
  
    const [filteredCommunes, setFilteredCommunes] = useState([]); // Communes for the selected Wilaya
    const [authToken, setAuthToken] = useState('A7wCBkaE49PMx99Snl6jWQqE6gdBoQ8c');
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 960) {
            setIsMobile(true);
        } 
    }, []);

    const [loading, setLoading] = useState(false)
      // Extract unique Wilayas from the JSON data
      const wilayas = communes.reduce((acc, item) => {
        if (!acc.some(w => w.wilaya_code === item.wilaya_code)) {
          acc.push({ wilaya_name: item.wilaya_name_ascii, wilaya_code: item.wilaya_code });
        }
        return acc;
      }, []);


  const [formData, setFormData] = useState({
    checkboxes: [false, false, false],
    selectedUniversity: null,

    selectedWilaya: "",
    selectedCommune: "",
    name: "",
    fname: "",
    email: "",
    phone: "",
    skills: [],
    technologies: [],
    q1: "",
    q2: "",
    q3: "",
    isWorking: false,
    actualWork: "",
    website: "",
    hasCompany: false,
    userType: "student",
    captchaToken: null,
  });


  const handleSkills = (selectedTags) => {
    console.log("Tags sélectionnés:", selectedTags);
    setFormData({ ...formData, skills: selectedTags });

};

const handleTechnologies = (selectedTags) => {
    console.log("Tags sélectionnés:", selectedTags);
    setFormData({ ...formData, technologies: selectedTags });
    console.log(formData);
}

  const skills = [
    "Web Development", "Mobile Development", "Artificial Intelligence",
    "Web Scraping", "NLP", "Computer Vision", "Data Science", "Machine Learning",
    "Deep Learning", "Cybersecurity", "Blockchain", "Cloud Computing", "DevOps",
    "UI/UX Design", "Project Management", "Big Data", "Software Engineering",
    "Quantum Computing", "Game Development", "Embedded Systems", "IoT",
    "Augmented Reality", "Virtual Reality", "Robotics", "Database Management"
  ];

  const technologies = [
    "Python", "JavaScript", "Java", "C++", "C#", "PHP", "Swift", "Kotlin", "Ruby", "Go", "Rust", "TypeScript", "Flutter", "React", "React Native", "Tenserflow", "PyTorch", "Numpy", "Pandas", "Matplotlib", "Seaborn", "Scikit-learn", "NLTK", "Spacy", "OpenCV", "Django", "Flask", "Spring", "Node.js", "Express", "Angular", "Vue.js", "Svelte", "Laravel", "Symfony", "Rails", "ASP.NET", "Unity", "Unreal Engine", "MongoDB", "MySQL", "PostgreSQL", "SQLite", "Firebase", "AWS", "Azure", "Google Cloud", "Docker", "Kubernetes", "Git"];

    const handleSubmit = async (e) => {

        setLoading(true);

        if (!formData.captchaToken) {
            console.error('No captcha token provided');
            setLoading(false);
            return;
        }      
        // Copy formData and modify selectedUniversity to selectedUniversity.name
        const formData2 = {
          ...formData,
          selectedUniversity: formData.selectedUniversity?.name || null, // Use selectedUniversity.name, or null if it's not set
        };
      
        console.log(formData2);
      
        try {
          // Make the API request to join the club
          const response = await joinClub(authToken, formData2);
      
          // Check the response for success
          if (response.success) {
            // Redirect to the home page on success
            window.location.href = "/";
          } else {
            // Log the response in case of an error
            console.error(response);
          }
        } catch (error) {
          // Log any errors that occur during the API request
          console.error('Error joining the club:', error);
        } finally {
          setLoading(false); // Ensure loading state is reset
        }
      };
      


  // Filter communes based on the selected Wilaya
  useEffect(() => {
    if (formData.selectedWilaya) {
      const communesForWilaya = communes.filter(
        (commune) => commune.wilaya_code == formData.selectedWilaya
      );
      console.log(communesForWilaya);
      setFilteredCommunes(communesForWilaya);
      // selectedcommune
      setFormData({ ...formData, selectedCommune: "" });
    }
  }, [formData.selectedWilaya]);

  const [currentStep, setCurrentStep] = useState(0);
  const refs = useRef({});

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...formData.checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setFormData({ ...formData, checkboxes: updatedCheckboxes });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e) => {
    setFormData({ ...formData, selectValue: e.target.value });
  };


  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
const nextStep = () => {
    if (validateStep()) {
        if (currentStep === steps.length - 1) {
            console.log(formData);
            // handlesubmit
            handleSubmit();
        } else {
            setCurrentStep(currentStep + 1);
        }
    }
};

const validateStep = () => {
    if (currentStep === 0) {
        return formData.checkboxes.filter(Boolean).length === 3;
    }

    if (currentStep === 1) {
        return formData.selectedUniversity;
    }
    if (currentStep === 2) {
        return formData.selectedWilaya && formData.selectedCommune;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(00213|\+213|0)(5|6|7)[0-9]{8}$/;
    const nameRegex = /^[a-zA-Z\s-]+$/;

    if (currentStep === 3) {
        return (
            formData.name && nameRegex.test(formData.name) &&
            formData.fname && nameRegex.test(formData.fname) &&
            formData.email && emailRegex.test(formData.email) &&
            formData.phone && phoneRegex.test(formData.phone)
        );
    }

    return true;
};

  useEffect(() => {
    if (refs.current[currentStep]) {
      refs.current[currentStep].focus();
    }
  }, [currentStep]);






const steps = [
    {
        title: "Step 1",
        content: (
            <div className="form">
                <Box display="flex" flexDirection="column">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.checkboxes[0]}
                                onChange={() => handleCheckboxChange(0)}
                                sx={{ color: "#0a4d80", '&.Mui-checked': { color: "#0a4d80" } }}
                            />
                        }
                        label="I agree with the terms and conditions of the club of ITRC"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.checkboxes[1]}
                                onChange={() => handleCheckboxChange(1)}
                                sx={{ color: "#0a4d80", '&.Mui-checked': { color: "#0a4d80" } }}
                            />
                        }
                        label="I agree with the terms of services and the privacy policy of the plateform of ITRC"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.checkboxes[2]}
                                onChange={() => handleCheckboxChange(2)}
                                sx={{ color: "#0a4d80", '&.Mui-checked': { color: "#0a4d80" } }}
                            />
                        }
                        label="I'm actually a student / teacher in a : university / private school, or a partner of ITRC"
                    />
                    <br/><br/>
                    <Button variant="contained" onClick={nextStep} disabled={formData.checkboxes.filter(Boolean).length < 3} sx={{ boxShadow: "none", backgroundColor: "#0a4d80", '&:hover': { backgroundColor: "#083b60" } }}>
                        Next
                    </Button>
                </Box>
            </div>
        ),
    },
  
    {
        title: "Step 2",
        content: (
            <Box 
                          sx = {{
                                            width: isMobile ? '80vw' : '500px',
                                        }}
            display="flex" flexDirection="column">
                <Universities
                value= {formData.selectedUniversity}
                onSelectionChange={(selectedUniversity) => setFormData({ ...formData, selectedUniversity: selectedUniversity })} 
                />
                <br/><br/>
                <Button variant="contained" onClick={nextStep} disabled={!formData.selectedUniversity}   sx={{ boxShadow: "none", backgroundColor: "#0a4d80", '&:hover': { backgroundColor: "#083b60" } }} >
                    Next
                </Button>
                <br/>
                <Button variant="outlined" onClick={previousStep} sx={{ boxShadow: "none", color: "#083b60", borderColor: "#083b60" }}>
                    Back
                </Button>
            </Box>
        ),
    },
    {
        title: "Step 3",
        content: (
            <Box 
            sx = {{
                              width: isMobile ? '80vw' : '500px',
                          }}
display="flex" flexDirection="column">
            <Box 
              display="flex" 
              flexDirection="column"
              sx={{ 
                gap: 3,
                width: '100%',  // Box prend toute la largeur disponible
              }}
            >
              <Autocomplete
                id="wilaya-autocomplete"
                fullWidth  // Assure que l'Autocomplete prend toute la largeur
                options={wilayas}
                getOptionLabel={(option) => option.wilaya_name || ''}
                value={wilayas.find(w => w.wilaya_code === formData.selectedWilaya) || null}
                onChange={(_, newValue) => {
                  setFormData({ 
                    ...formData, 
                    selectedWilaya: newValue?.wilaya_code || '',
                    selectedCommune: ''
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth  // Assure que le TextField prend toute la largeur
                    label="Wilaya"
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#0a4d80',
                        },
                        '&:hover fieldset': {
                          borderColor: '#083b60',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#0a4d80',
                        },
                      }
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box 
                    component="li" 
                    {...props}
                    sx={{
                      padding: '10px 16px',
                      '&:hover': {
                        backgroundColor: '#f5f5f5'
                      }
                    }}
                  >
                    {option.wilaya_name}
                  </Box>
                )}
              />
      
              {formData.selectedWilaya && (
                <Autocomplete
                  id="commune-autocomplete"
                  fullWidth  // Assure que l'Autocomplete prend toute la largeur
                  options={filteredCommunes}
                  getOptionLabel={(option) => option.commune_name_ascii || ''}
                  value={filteredCommunes.find(c => c.id === formData.selectedCommune) || null}
                  onChange={(_, newValue) => {
                    setFormData({ 
                      ...formData, 
                      selectedCommune: newValue?.id || '' 
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth  // Assure que le TextField prend toute la largeur
                      label="Commune"
                      variant="outlined"
                   
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box 
                      component="li" 
                      {...props}
                      sx={{
                        padding: '10px 16px',
                        '&:hover': {
                          backgroundColor: '#f5f5f5'
                        }
                      }}
                    >
                      {option.commune_name_ascii}
                    </Box>
                  )}
                />
              )}
      
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  width: '100%',  // Box des boutons prend toute la largeur
                }}
              >
                <Button 
                  fullWidth  // Bouton prend toute la largeur
                  variant="contained" 
                  onClick={nextStep} 
                  disabled={!formData.selectedWilaya || !formData.selectedCommune}
                  sx={{ boxShadow: "none", backgroundColor: "#0a4d80", '&:hover': { backgroundColor: "#083b60" } }}
                >
                  Next
                </Button>
                <Button 
                  fullWidth  // Bouton prend toute la largeur
                  variant="outlined" 
                  onClick={previousStep} 
                  sx={{ boxShadow: "none", color: "#083b60", borderColor: "#083b60" }}
                >
                  Back
                </Button>
              </Box>
            </Box>
            </Box>
        ),
      },
    {
        title: "Step 4",
        content: (
            <Box 
                          sx = {{
                                            width: isMobile ? '80vw' : '500px',
                                        }}
            display="flex" flexDirection="column">
                <Input1
                    label="First Name(s)"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    inputRef={(el) => (refs.current[2] = el)}
                    fullWidth
                    margin="normal"
                    autoComplete="off"
                    icon= {<AccountCircle/>}
                />
                <Input1
                    label="Family Name"
                    name="fname"
                    value={formData.fname}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    autoComplete="off"

                    icon= {<AccountCircle/>}
                />
                <Input1
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    autoComplete="off"

                    icon= {<AlternateEmail/>}
                />
                <Input1
                    label="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    autoComplete="off"

                    icon= {<Phone/>}
                />
                <br/> <br/>
                <Button variant="contained" onClick={nextStep} disabled={!formData.name || !formData.fname || !formData.email || !formData.phone}   sx={{ boxShadow: "none", backgroundColor: "#0a4d80", '&:hover': { backgroundColor: "#083b60" } }}>
                    Next
                </Button> <br/>
                <Button variant="outlined" onClick={previousStep} sx={{ boxShadow: "none", color: "#083b60", borderColor: "#083b60" }}>
                    Back
                </Button>
            </Box>
        ),
    },
    {
        title: "Step 5",
        content: (
            <Box 
                          sx = {{
                                            width: isMobile ? '80vw' : '500px',
                                        }}
            display="flex" flexDirection="column">
             <TagSelector
        tags={skills}
        value={formData.skills}
        limit={10} // Limite à 10 tags sélectionnables
        onSelectionChange={handleSkills}
      />
                <br/> <br/>
                <Button variant="contained" onClick={nextStep} disabled={!formData.name || !formData.fname || !formData.email || !formData.phone}  sx={{ boxShadow: "none", backgroundColor: "#0a4d80", '&:hover': { backgroundColor: "#083b60" } }}>
                    Next
                </Button> <br/>
                <Button variant="outlined" onClick={previousStep} sx={{ boxShadow: "none", color: "#083b60", borderColor: "#083b60" }}>
                    Back
                </Button>
            </Box>
        ),
    },
    {
        title: "Step 6",
        content: (
            <Box 
                          sx = {{
                                            width: isMobile ? '80vw' : '500px',
                                        }}
            display="flex" flexDirection="column">
             <TagSelector
        tags={technologies}
        value={formData.technologies}
        limit={100} // Limite à 10 tags sélectionnables
        onSelectionChange={handleTechnologies}
      />
                <br/> <br/>
                <Button variant="contained" onClick={nextStep} disabled={!formData.name || !formData.fname || !formData.email || !formData.phone}  sx={{ boxShadow: "none", backgroundColor: "#0a4d80", '&:hover': { backgroundColor: "#083b60" } }}>
                    Next
                </Button> <br/>
                <Button variant="outlined" onClick={previousStep}   sx={{ boxShadow: "none", color: "#083b60", borderColor: "#083b60" }}>
                    Back
                </Button>
            </Box>
        ),
    },
    {
        title: "Step 7",
        content: (
            <div className="form">
                <Box 
                            sx={{
                                width: isMobile ? '80vw' : '500px',
                            }}
                            display="flex" flexDirection="column">
                    <TextArea1
                        label="Why do you want to join the club of ITRC ?"
                        name="q1"
                        value={formData.q1}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextArea1
                        label="What are your expectations from the club of ITRC ?"
                        name="q2"
                        value={formData.q2}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextArea1
                        label="How did you hear about the club of ITRC ?"
                        name="q3"
                        value={formData.q3}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <br/><br/>
                    <Button variant="contained" onClick={nextStep} disabled={!formData.q1 || !formData.q2 || !formData.q3} sx={{ boxShadow: "none", backgroundColor: "#0a4d80", '&:hover': { backgroundColor: "#083b60" } }}>
                        Next
                    </Button>
                    <br/>
                    <Button variant="outlined" onClick={previousStep}  sx={{ boxShadow: "none", color: "#083b60", borderColor: "#083b60" }}>
                        Back
                    </Button>
                </Box>
            </div>
        ),

    },
    {
        title: "Step 8",
        content: (
            <Box 
                          sx = {{
                                            width: isMobile ? '80vw' : '500px',
                                        }}
            display="flex" flexDirection="column">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.isWorking}
                            onChange={() => setFormData({ ...formData, isWorking: !formData.isWorking })}
                            sx={{ color: "primary.main", '&.Mui-checked': { color: "secondary.main" } }}
                        />
                    }
                    label="Are you actually working?"
                />
                {formData.isWorking && (
                    <TextArea1
                        label="Actual Work"
                        name="actualWork"
                        value={formData.actualWork}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.ownCompany}
                            onChange={() => setFormData({ ...formData, ownCompany: !formData.ownCompany })}
                            sx={{ color: "primary.main", '&.Mui-checked': { color: "secondary.main" } }}
                        />
                    }
                    label="Do you own a company?"
                /><br/>
        <Typography
        sx= {{
            textAlign: "left"
        }}
        >I'm a:</Typography>
        <RadioGroup
  aria-label="user-type"
  name="user-type"
  value={formData.userType} // Utiliser 'userType' dans formData pour stocker la valeur sélectionnée
  onChange={(e) => setFormData({ ...formData, userType: e.target.value })} // Mettre à jour 'userType'
  row // Affiche les boutons radio sur une seule ligne
>
  <FormControlLabel 

  value="student" control={<Radio />} label="Student" />
  <FormControlLabel value="teacher" control={<Radio />} label="Teacher" />
  <FormControlLabel value="other" control={<Radio />} label="Other" />
</RadioGroup>
                <Input1
                    label="Website / Portfolio (optional)"
                    name="website"
                    value={formData.website}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                  <HCaptcha
        sitekey="174a219e-bcfa-4132-8d78-0f38a41425fa"
        onVerify={(token) => setFormData({ ...formData, captchaToken: token })}
        onExpire={() => setFormData({ ...formData, captchaToken: null })}
        />

                <br/><br/>
                <Button 
                
                variant="contained" onClick={nextStep}
                
                disabled={loading}

                sx={{
                  background: '#083b60',
                  color: '#fff',
                  fontFamily: 'InterVariable',
                  padding: '10px 20px',
                  width: '100%',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',

                }}
              >
                {loading ? (
                  <CircularProgress
                    size={24} // Taille de l'icône
                    color='#f9f9f9'
                    className="spinner-icon" // Classe CSS pour appliquer l'animation
                  />
                ) : (
                  <span>
                    Submit
                  </span>
                )}
              </Button>

                <br/>
                <Button variant="outlined" onClick={previousStep}  sx={{ boxShadow: "none", color: "#083b60", borderColor: "#083b60" }}>
                    Back
                </Button>
            </Box>
        ),
    }
];
const progress = ((currentStep + 1) / steps.length) * 100;

return (
    <>

<Helmet>
        <title>Join the ITRC Club - I.T Research Community</title>
        <meta name="description" content="Join the ITRC Club and get access to exclusive resources, events, and opportunities, fill the form and reply to the questions." />
        <meta property="og:title" content="Join the ITRC Club - I.T Research Community" />
        <meta property="og:description" content="Join the ITRC Club and get access to exclusive resources, events, and opportunities, fill the form and reply to the questions." />
      </Helmet>
    <Box className="big-container">
                                 {/* Progress Bar */}
                                 <Box sx={{ position: 'absolute', bottom: '0', left: '0', right: '0', zIndex: '999', padding: '1rem', backgroundColor: 'white' }}>
                            <Typography 
                            sx={{ color: '#083b60', fontFamily: 'InterVariable' }}
                            variant="h6">{`${currentStep + 1} / ${steps.length}`}</Typography>
                            <LinearProgress 
                            variant="determinate" value={progress} sx={{ 
                                    height: '10px', borderRadius: '5px',    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#083b60'
                                      }}} />
                    </Box>

        <TransitionGroup component={null}>
            <CSSTransition key={currentStep} timeout={500} classNames="fade">
                <div className="store-container">{steps[currentStep].content}</div>
            </CSSTransition>
        </TransitionGroup>
    </Box>
    </>
);
};

export default Join;
